import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import profileimage from "../../imgs/profile.png";
import { useUserAuth } from "../../Context/UseAuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Register.css";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { Loading } from "../../components/Loading/Loading";

export const Register = () => {
  const fileInputRef = useRef(null);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");

  const [confirmPass, setconfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [errors, setErrors] = useState({});

  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);

  //regarding otp verification
  const [otp, setOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [showEmailOtpInput, setShowEmailOtpInput] = useState(false);

  const emailOtpGeneratoin = async (email) => {
    if (!email) {
      return toast.error("Please Enter Your Email", { duration: 6000 });
    }

    if (!validateEmail(email)) {
      return toast.error("Please Enter Valid Email", { duration: 6000 });
    }
    if (!validateEmailDomain(email)) {
      return toast.error("Please Enter Valid Domain Email", { duration: 6000 });
    }

    if (!emailVerified) {
      try {
        setLoading(true);

        const url = "https://smartuter.com/api/enduser/email/otp/generation";
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        };
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.status) {
          setLoading(false);

          setShowEmailOtpInput(true);
          toast.success(`We Have send an Email to ${email} ID`, {
            duration: 6000,
          });
        }
      } catch (error) {
        console.log("error");
        setLoading(false);
      }
    } else {
      toast.success("Email already verified", { duration: 7000 });
    }
  };

  const emailOtpVerification = async ({ email, otp }) => {
    try {
      const url = "https://smartuter.com/api/enduser/email/otp/verification";
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      };
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status) {
        setEmailVerified(true);
        setShowEmailOtpInput(false);
      } else {
        toast.error(
          "Invalid OTP, please Enter Valid OTP OR Check Your Email ID",
          { duration: 7000 }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const commonEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "icloud.com",
    "aol.com",
  ];

  const navigate = useNavigate();

  //added
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateEmailDomain = (email) => {
    const domain = email.split("@")[1];
    return commonEmailDomains.includes(domain);
  };

  const validateNumber = (number) => {
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  useEffect(() => {
    const validateAndCheckEmail = async () => {
      if (email && validateEmail(email)) {
        const validDomain = validateEmailDomain(email);
        setEmailValid(validDomain);
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: !validDomain ? "Email domain is not valid" : "",
        }));
      }
    };
    validateAndCheckEmail();
  }, [email]);

  const handleSubmit = () => {
    let formErrors = {};

    if (!name) {
      formErrors.name = "Username is required";
    }
    if (!validateEmail(email)) {
      formErrors.email = "Invalid email format";
    } else if (!emailValid) {
      formErrors.email = "Email domain is not valid";
    }
    if (!validateNumber(number)) {
      formErrors.number = "Number must be 10 digits";
    }
    if (!validatePassword(pass)) {
      formErrors.pass = "Password must be at least 6 characters";
    }
    if (pass !== confirmPass) {
      formErrors.confirmPass = "Passwords do not match";
    }
    if (!emailVerified) {
      formErrors.emailOtpVer = "Email is Not Verified Please click on Verify";
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const payload = {
        email,
        pass,
        name,
        number,
        isVerified: true,
        profileUrl,
      };
      fetch("https://smartuter.com/api/subadmin/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          toast.success(`${res.msg}`);
          setEmail("");
          setPass("");
          setNumber("");
          setName("");
          (res.msg === "User registered successfully." ||
            res.msg ===
              "User already exists. Please choose a different mail.") &&
            navigate("/login");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await fetch(
          "https://smartuter.com/api/enduser/uploadimage",
          {
            method: "POST",
            body: formData,
          }
        );
        if (response.ok) {
          const data = await response.json();
          setProfileUrl(data.fileUrl);
        } else {
          console.error("Error uploading file:", response.statusText);
          throw new Error("Upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Upload failed: " + error.message);
      }
    }

    // Handle the selected file (e.g., upload or display it)
  };
  const { darkThemesetter } = useUserAuth();
  const loginContainer = darkThemesetter ? "login-darkmode" : "login-lightmode";
  const loginHeading = darkThemesetter ? "login-heading" : "";

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="main_login">
      <div className={`login-container ${loginContainer}`}>
        <h1 className={loginHeading}>Register</h1>
        <div className="input-group">
          {/* Display default profile image */}
          <img
            src={profileUrl ? profileUrl : profileimage}
            style={{
              height: "50px",
              width: "50px",
              margin: "auto",
              alignItems: "center",
              display: "flex",
            }}
            alt="Profile"
            className="profile-image"
            onClick={() => fileInputRef.current.click()}
          />
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>

        <div className="input-group">
          <label>Name:</label>
          <input
            type="text"
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          {errors.name && <span className="error-message">*{errors.name}</span>}
        </div>

        <div className="input-group">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <span
            className="register-eyebtn"
            onClick={() => emailOtpGeneratoin(email)}
          >
            {emailVerified ? (
              <span style={{ color: "green", backgroundColor: "white" }}>
                Verified
              </span>
            ) : (
              <span style={{ color: "blue", backgroundColor: "white" }}>
                Verify
              </span>
            )}
          </span>
          {errors.email && (
            <span className="error-message">*{errors.email}</span>
          )}
          {errors.emailOtpVer && emailVerified === false && (
            <span className="error-message">*{errors.emailOtpVer}</span>
          )}
        </div>
        {showEmailOtpInput ? (
          <div className="input-group">
            <label>Enter Your OTP Here:</label>
            <input
              type="text"
              placeholder="Enter Your Otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <span
              className="register-eyebtn"
              onClick={() => emailOtpVerification({ email, otp })}
            >
              <span>Submit</span>
            </span>
            {errors.email && (
              <span className="error-message">*{errors.email}</span>
            )}
          </div>
        ) : null}
        <div className="input-group">
          <label>Mobile:</label>
          <input
            type="number"
            placeholder="Enter Your Mobile Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
          />
          {errors.number && (
            <span className="error-message">*{errors.number}</span>
          )}
        </div>

        <div className="input-group">
          <label>Password:</label>
          <input
            type={showPass ? "text" : "password"}
            placeholder="Enter Your password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            required
          />
          <span
            className="register-eyebtn"
            onClick={() => setShowPass(!showPass)}
          >
            {!showPass ? (
              <FaEyeSlash color="#A7A9AA" />
            ) : (
              <FaEye color="#A7A9AA" />
            )}
          </span>
          {errors.pass && <span className="error-message">*{errors.pass}</span>}
        </div>

        <div className="input-group">
          <label>Confirm Password:</label>
          <input
            type={showConfirmPass ? "text" : "password"}
            placeholder="Confirm password"
            value={confirmPass}
            onChange={(e) => setconfirmPass(e.target.value)}
            required
          />
          <span
            className="register-eyebtn1"
            onClick={() => setShowConfirmPass(!showConfirmPass)}
          >
            {!showConfirmPass ? (
              <FaEyeSlash color="#A7A9AA" />
            ) : (
              <FaEye color="#A7A9AA" />
            )}
          </span>
          {errors.confirmPass && (
            <span className="error-message">*{errors.confirmPass}</span>
          )}
        </div>

        <button className="register-button" onClick={handleSubmit}>
          Register
        </button>
        <p>
          Already have an account?
          <NavLink className={"navlink"} to="/login">
            Login
          </NavLink>
        </p>
      </div>
    </div>
  );
};
