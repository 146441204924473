import React from "react";
// import Cards from "../Cards/Cards";
// import Table from "../Table/Table";
import { useUserAuth } from "../../Context/UseAuthContext";
// import NavbarDashboard from "../NavbarDashboard/NavbarDashboard";
import "./MainDash.css";
import { AllRoutes } from "../AllRoutes";

const MainDash = () => {
  const { darkThemesetter } = useUserAuth();
  const backgroundColor = darkThemesetter ? "dark":"light"
  return (
    <div className={`MainDash ${backgroundColor}`}>
      {/* <h1>Dashboard</h1> */}
      {/* <Cards />
      <Table /> */}
      {/* <div className="maindash-nav">
       <NavbarDashboard/>
       </div> */}
       <div className="mainDash-two">
      <AllRoutes className={`${backgroundColor}`}/>
      </div>
    </div>
  );
};

export default MainDash;
