import React, { useState } from "react";
import "./Updates.css";
import profie from "../../imgs/profile.png";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";

const Updates = ({ user, getUserData }) => {
  const navigate = useNavigate();
  const [loading] = useState(false);

  // const [updatevalue, setUpdatevalue] = useState("");
  // const [updateName, setUpdateName] = useState("");
  // const [updateEmail, setUpdateEmail] = useState("");
  // const [updateNumber, setUpdateNumber] = useState("");
  // const [profileUrl, setProfileUrl] = useState("");

  // const handleEditName = (v) => {
  //   setUpdatevalue(v);
  // };
  // const handleUpdate = () => {
  //   const id = user._id;
  //   let payload;
  //   if (updatevalue === "name") {
  //     payload = { name: updateName };
  //   } else if (updatevalue === "email") {
  //     payload = { email: updateEmail };
  //   } else if (updatevalue === "number") {
  //     payload = { number: updateNumber };
  //   } else {
  //     payload = { profileUrl: profileUrl };
  //   }

  //   fetch(`https://smartuter.com/api/subadmin/edit/${id}`, {
  //     method: "PATCH",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,

  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(payload),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setUpdatevalue("");
  //       getUserData();
  //       getUserData();
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const handleImageChange = async (event) => {
  //   setLoading(true);
  //   const file = event.target.files[0];
  //   if (file.size > 1 * 1024 * 1024) {
  //     toast.error("Upload File Less Than 1 MB", {
  //       duration: 6000,
  //     });
  //     setLoading(false);
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("file", file);
  //   const response = await fetch(
  //     "https://smartuter.com/api/enduser/uploadimage",
  //     {
  //       method: "POST",
  //       body: formData,
  //     }
  //   );
  //   if (response.ok) {
  //     const data = await response.json();
  //     setProfileUrl(data.fileUrl);
  //     setLoading(false);
  //   } else {
  //     console.error("Error uploading file:", response.statusText);
  //     throw new Error("Upload failed");
  //   }

  // };

  return (
    <div className="Updates">
      {loading && (
        <div className="loading-overlay">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      )}
      {/* {UpdatesData.map((update) => {
        return ( */}
      <div className="update" onClick={() => navigate("/profile")}>
        <img src={user.profileUrl ? user.profileUrl : profie} alt="profile" />
        <div className="noti">
          <div style={{ marginBottom: "0.5rem" }}>
            {/* <span>{user.name}</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updates;
