import React from 'react'
import Projecttable from '../../components/ProjectTable/Projecttable'
import { useUserAuth } from '../../Context/UseAuthContext';
import "./CreateProject.css"

const CreateProject = () => {
  const {darkThemesetter}=useUserAuth();
  const projectBackground = darkThemesetter ? "project-darktheme-color" :"project-lighttheme-color"
  return (
    <div className={projectBackground}>
      {/* <h1 className={`create-course-heading ${color}`}>Create Project</h1> */}
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <h1>Create Project</h1>
        <p>Creating Comprehensive Project for Smartuter</p>
        <hr />
      </div>
      <Projecttable />
    </div>
  );
}

export default CreateProject