import { useEffect } from "react";
import "./App.css";
import MainDash from "./components/MainDash/MainDash";
import ContentPage from "./components/contentpage/ContentPage";
import { ColorRing } from "react-loader-spinner";
import Sidebar from "./components/Sidebar/Sidebar";
import { useUserAuth } from "./Context/UseAuthContext";
import NavbarDashboard from "./components/NavbarDashboard/NavbarDashboard";
import { Toaster } from "react-hot-toast";



function App() {
  const { darkThemeSetter, token, loading } = useUserAuth();

  // useEffect(() => {
  //   // if(!token){
  //   //   navigate("/login");
  //   // }
  // },[]);

  const backgroundColor = darkThemeSetter ? "App-dark" : "App-light";

  if (loading) {
    return (
      <div className="centered-container">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />
      </div>
    );
  }

  if (token) {
    return (
      <>
        <div className={`App ${backgroundColor}`}>
          <Toaster  />
          <NavbarDashboard />
          <div className={`AppGlass ${backgroundColor}`}>
            {/* <ContentPage/> */}
            <Sidebar />
            <MainDash />
            {/* <RightSide /> */}
            {/* <AllRoutes/> */}
          </div>
        </div>
      </>
    );
  }
  return <ContentPage />;
}

export default App;
