import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const ForgotPassword = () => {
  const { id, token } = useParams();
  const { navigate } = useUserAuth();
  const [pass, setPassword] = useState("");

  const [confirmPass, setconfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const userValid = async () => {
    const res = await fetch(
      `https://smartuter.com/api/subadmin/forgotpassword/${id}/${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (data.status === 201) {
      console.log("User Valid");
    } else {
      // navigate("/error");
    }
  };

  const setVal = (e) => {
    setPassword(e.target.value);
  };

  const sendPassword = async (e) => {
    e.preventDefault();
    if (!validatePassword(pass)) {
      return toast.error("Password must be at least 6 characters");
    }
    if (pass !== confirmPass) {
      return toast.error("Passwords do not match");
    }
    const res = await fetch(
      `https://smartuter.com/api/subadmin/${id}/${token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pass }),
      }
    );

    const data = await res.json();
    if (data.status === 201) {
      setPassword("");
      toast.success("password update sucessfully");
      navigate("/login");
    } else {
      navigate("/login");

      toast.error("! Token Expired generate new link");
    }
  };
  useEffect(() => {
    userValid();
  }, []);

  return (
    <div className="login-container">
      <h1>Forgot Password</h1>
      <form onSubmit={sendPassword}>
        <input
          type="password"
          name="pass"
          placeholder="Enter Your new password"
          value={pass}
          onChange={setVal}
          className="input-group forgetpassword-input"
        />

        {/* <input
          className="input-group forgetpassword-input"

                type={showConfirmPass ? "text" : "password"}
                placeholder="Confirm password"
                value={confirmPass}
                onChange={(e) => setconfirmPass(e.target.value)}
                required
              />
              <span
                className="register-eyebtn"
                onClick={() => setShowConfirmPass(!showConfirmPass)}
              >
                {!showConfirmPass ? (
                  <FaEyeSlash color="#A7A9AA" />
                ) : (
                  <FaEye color="#A7A9AA" />
                )}
              </span> */}

        <div className="input-group">
          <label>Confirm Password:</label>
          <input
            type={showConfirmPass ? "text" : "password"}
            placeholder="Confirm password"
            value={confirmPass}
            onChange={(e) => setconfirmPass(e.target.value)}
            required
          />
          <span
            className="register-eyebtn1"
            onClick={() => setShowConfirmPass(!showConfirmPass)}
          >
            {!showConfirmPass ? (
              <FaEyeSlash color="#A7A9AA" />
            ) : (
              <FaEye color="#A7A9AA" />
            )}
          </span>
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
