// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-studymaterial-card{
    /* background-color: rgb(245, 246, 243); */
    padding: 0;
    font-family: "system-ui";
}
.singlepage-darkcolor{
    background-color: rgb(37, 37, 37); 
    /* box-shadow: 0px 1px 5px 0px rgb(135, 175, 255); */
    color: white;
}
.singlepage-lightcolor{
    background-color:#ffffff;
    box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.1);
    color:#333333;
}

.single-post-sub-card{
    display: flex;
    justify-content: space-around;
    padding: 0 !important;
    /* background-color: greenyellow;  */
}
.single-Studymaterial-media{
    width: 40%;
}
.single-studymaterial-info{
    width: 60%;
    padding-left: 8%;
}
.single-studymaterial-image{
    width: 100%;
    height: 65vh!important;
}
.sinlge-project-head{
    font-weight: 600 !important;
}
@media screen and (max-width:767px){
    .single-post-sub-card{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .single-Studymaterial-media{
      
       width: 100%;
    }
    .single-studymaterial-info{
      width: 100%;
    }
    .single-studymaterial-image{
        width: 100%;
    } 
}

.single-project-description{
    margin-top: 20px!important;
    margin-bottom: 10px!important;
}`, "",{"version":3,"sources":["webpack://./src/components/StudyMaterial/SingleStudyMaterial/SingleStudyMaterial.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,UAAU;IACV,wBAAwB;AAC5B;AACA;IACI,iCAAiC;IACjC,oDAAoD;IACpD,YAAY;AAChB;AACA;IACI,wBAAwB;IACxB,+CAA+C;IAC/C,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,qBAAqB;IACrB,oCAAoC;AACxC;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,sBAAsB;AAC1B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,uBAAuB;IAC3B;IACA;;OAEG,WAAW;IACd;IACA;MACE,WAAW;IACb;IACA;QACI,WAAW;IACf;AACJ;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC","sourcesContent":[".single-studymaterial-card{\n    /* background-color: rgb(245, 246, 243); */\n    padding: 0;\n    font-family: \"system-ui\";\n}\n.singlepage-darkcolor{\n    background-color: rgb(37, 37, 37); \n    /* box-shadow: 0px 1px 5px 0px rgb(135, 175, 255); */\n    color: white;\n}\n.singlepage-lightcolor{\n    background-color:#ffffff;\n    box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.1);\n    color:#333333;\n}\n\n.single-post-sub-card{\n    display: flex;\n    justify-content: space-around;\n    padding: 0 !important;\n    /* background-color: greenyellow;  */\n}\n.single-Studymaterial-media{\n    width: 40%;\n}\n.single-studymaterial-info{\n    width: 60%;\n    padding-left: 8%;\n}\n.single-studymaterial-image{\n    width: 100%;\n    height: 65vh!important;\n}\n.sinlge-project-head{\n    font-weight: 600 !important;\n}\n@media screen and (max-width:767px){\n    .single-post-sub-card{\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n    }\n    .single-Studymaterial-media{\n      \n       width: 100%;\n    }\n    .single-studymaterial-info{\n      width: 100%;\n    }\n    .single-studymaterial-image{\n        width: 100%;\n    } \n}\n\n.single-project-description{\n    margin-top: 20px!important;\n    margin-bottom: 10px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
