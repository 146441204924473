import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Box,
} from "@mui/material";
import { useUserAuth } from "../../Context/UseAuthContext";

export const Payment = () => {
  const [salesData, setSalesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useUserAuth();
  const [count, setCount] = useState(0);

  const limit = 5;

  useEffect(() => {
    if (user) {
      fetchSalesData(currentPage, limit);
    }
  }, [currentPage]);

  const fetchSalesData = async (page, limit) => {
    const response = await fetch(
      `https://smartuter.com/api/enduser/vendorsalesdata?vendorId=${user._id}&page=${page}&limit=${limit}`
    );
    const data = await response.json();

    // Flatten sales data to include each item in totalSaleItems and filter out empty sales
    const flattenedSalesData = data.salesData
      .flatMap((data) => data.sale)
      .flatMap((sale) =>
        sale.totalSaleItems.map((item) => ({
          ...item,
          orderId: sale.orderId,
          paymentId: sale.paymentId,
          totalSaleAmount: sale.totalSaleAmount,
          saleDate: sale.saleDate,
        }))
      )
      .filter((item) => item.vendorId === user._id);

    setSalesData(flattenedSalesData);
    setTotalPages((prv) => prv + 1); // Example total pages
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setCount((value - 1) * 5);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Sales Data
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.NO</TableCell>

              <TableCell>Sale Date</TableCell>

              <TableCell>Order ID</TableCell>
              <TableCell>Course Name</TableCell>
              {/* <TableCell>Price</TableCell> */}
              {/* <TableCell>Platform Fee</TableCell> */}
              <TableCell>Amount</TableCell>
              <TableCell>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesData.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell>{index + 1 + count}</TableCell>

                <TableCell>
                  {new Date(item.saleDate).toLocaleString()}
                </TableCell>

                <TableCell>{item.orderId}</TableCell>
                <TableCell>{item.courseName}</TableCell>
                {/* <TableCell>{item.price}</TableCell> */}
                {/* <TableCell>{item.platformFee}</TableCell> */}
                <TableCell>{item.vendorAmount}</TableCell>
                <TableCell
                  className={
                    item.vendorPaymentStatus
                      ? "paymentComplated"
                      : "paymentPending"
                  }
                >
                  {item.vendorPaymentStatus ? item.vendorPaymentId : "Pending"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};
