// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NotificationPage.css */

.notification-page {
    position: absolute;
    top: 10%;
    right: 7%;
    background-color: rgb(255, 255, 255);
    /* border: 1px solid #f90000; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    /* padding: 10px; */
    width: 22vw;
    height: 50vh;
    z-index: 9999;
    overflow: scroll;

  }
  .notification-page>div>a{
  }
  .notification-page>div>a{
    border-bottom: 1px solid rgb(34, 255, 0);
  
  }
  .notification-page>div>a>div{
    
    width: 95%;
    margin:2%  ;
   /* height: 10vh; */
   border-radius: 1vh ;

   display: flex;
   align-items: center;
   box-shadow: rgba(175, 153, 153, 0.2) 0px 2px 8px 0px;
  transition: background-color 2s linear,color 0.5s linear;
  }
  .notification-page>div>a>div:hover{
    background-color: black;
    color: white;
  }
  .notification-page>div>a>div>img{
    padding: 1vw;
    height: 2rem;
    width: 10%;
    border-radius: 3vh ;
  }
  .notification-page>div>a>div>h5{
    border-bottom: 1px solid red;
  }
 
  @media screen and (max-width:520px){
    .notification-page {
      width: 82vw;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Notification/notification.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,+BAA+B;IAC/B,uFAAuF;IACvF,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,gBAAgB;;EAElB;EACA;EACA;EACA;IACE,wCAAwC;;EAE1C;EACA;;IAEE,UAAU;IACV,WAAW;GACZ,kBAAkB;GAClB,mBAAmB;;GAEnB,aAAa;GACb,mBAAmB;GACnB,oDAAoD;EACrD,wDAAwD;EACxD;EACA;IACE,uBAAuB;IACvB,YAAY;EACd;EACA;IACE,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,mBAAmB;EACrB;EACA;IACE,4BAA4B;EAC9B;;EAEA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":["/* NotificationPage.css */\n\n.notification-page {\n    position: absolute;\n    top: 10%;\n    right: 7%;\n    background-color: rgb(255, 255, 255);\n    /* border: 1px solid #f90000; */\n    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;\n    border-radius: 5px;\n    /* padding: 10px; */\n    width: 22vw;\n    height: 50vh;\n    z-index: 9999;\n    overflow: scroll;\n\n  }\n  .notification-page>div>a{\n  }\n  .notification-page>div>a{\n    border-bottom: 1px solid rgb(34, 255, 0);\n  \n  }\n  .notification-page>div>a>div{\n    \n    width: 95%;\n    margin:2%  ;\n   /* height: 10vh; */\n   border-radius: 1vh ;\n\n   display: flex;\n   align-items: center;\n   box-shadow: rgba(175, 153, 153, 0.2) 0px 2px 8px 0px;\n  transition: background-color 2s linear,color 0.5s linear;\n  }\n  .notification-page>div>a>div:hover{\n    background-color: black;\n    color: white;\n  }\n  .notification-page>div>a>div>img{\n    padding: 1vw;\n    height: 2rem;\n    width: 10%;\n    border-radius: 3vh ;\n  }\n  .notification-page>div>a>div>h5{\n    border-bottom: 1px solid red;\n  }\n \n  @media screen and (max-width:520px){\n    .notification-page {\n      width: 82vw;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
