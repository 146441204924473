import React from 'react';
import { Button, } from '@mui/material';
import UpiDetailsDialog from './UpiDetailsDialog';
import { PiCopyThin } from 'react-icons/pi';
import toast from 'react-hot-toast';

const UpiDetailsDisplay = ({ handleOpenDialog, open, onClose, onSave, bankDetails }) => {
  const copyToClip = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(`UPI Copied`);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Enter UPI ID
      </Button>
      <UpiDetailsDialog open={open} onClose={onClose} onSave={onSave} />

      <div className="upi-details">
        <ul className="upi-details-main">
          {bankDetails &&
            bankDetails.map((upiDetail, index) => (
              <li className="singleenduser-upi-details" key={index}>
                <div onClick={() => copyToClip(upiDetail.upiid)}>
                  <label>UPI ID</label>
                  <p className="enuser-text" style={{ overflowX: "scroll" }}>
                    <span>{upiDetail.upiid}</span>
                    <PiCopyThin />
                  </p>
                </div>
              </li>
            ))}
        </ul>
      </div>

      {/* {bankDetails && bankDetails.map((e, i) => (
        <Paper key={i} style={{ padding: 16, marginTop: 16 }}>
          <Typography variant="h6">UPI ID Details</Typography>
          <Typography variant="body1">UPI ID: {e.upiid}</Typography>
        </Paper>
      ))} */}
    </div>
  );
};

export default UpiDetailsDisplay;
