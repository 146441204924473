import React, { useState, useRef } from "react";
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Avatar,
  Input,
} from "@mui/material";
import { ColorRing } from "react-loader-spinner";
import profie from "../../imgs/profile copy.png";
import "./Profile.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import axios from "axios";
import BankDetailsDisplay from "./BankDetails/BankDetailsDisplay";
import UpiDetailsDisplay from "./BankDetails/UpiDetailsDisplay";
import toast from "react-hot-toast";
const ProfileForm = () => {
  const { user, getUserData, closeForm, token, darkThemesetter } =
    useUserAuth();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [updatevalue, setUpdatevalue] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateNumber, setUpdateNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");

  const [profileView, setProfileView] = useState("profile");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [upidialogOpen, setupiDialogOpen] = useState(false);

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  // const postsText = darkThemesetter ? "dark-text" : "light-text";

  const inputColor = darkThemesetter ? "#f7f8fb" : "black";

  const toggleButton = darkThemesetter
    ? "toggle-button-dark-theme"
    : "toggle-button-light-theme";

  const handleOpenDialog = () => {
    setDialogOpen(!dialogOpen);
  };
  const handleOpenUpiDialog = () => {
    setupiDialogOpen(!upidialogOpen);
  };
  const handleSaveUPIDetails = async (e) => {
    const id = user._id;

    try {
      await axios.patch(`https://smartuter.com/api/subadmin/edit/${id}`, {
        upi: [...user.upi, e],
      });
      getUserData(token);
    } catch (error) {
      console.error("Error updating upi details:", error);
      // setMessage('An error occurred while updating bank details.');
    }
  };
  const handleSaveDetails = async (e) => {
    const id = user._id;

    try {
      await axios.patch(`https://smartuter.com/api/subadmin/edit/${id}`, {
        bankDetails: [...user.bankDetails, e],
      });

      getUserData(token);
    } catch (error) {
      console.error("Error updating bank details:", error);
      // setMessage('An error occurred while updating bank details.');
    }
  };
  const handleEditName = (v) => {
    setUpdatevalue(v);
  };

  const handleUpdate = async () => {
    const id = user._id;
    let payload;
    if (updatevalue === "name" && updateName) {
      payload = { name: updateName };
    } else if (updatevalue === "email" && updateEmail) {
      payload = { email: updateEmail };
    } else if (updatevalue === "number" && updateNumber) {
      payload = { number: updateNumber };
    } else {
      payload = { profileUrl: profileUrl };
    }

    try {
      const response = await fetch(
        `https://smartuter.com/api/subadmin/edit/${id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      await response.json();
      setUpdatevalue("");
      getUserData();
      closeForm();
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file.size > 1 * 1024 * 1024) {
      toast.error("Upload File Less Than 1 MB", {
        duration: 6000,
      });
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(
        "https://smartuter.com/api/enduser/uploadimage",
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setProfileUrl(data.fileUrl);
      } else {
        console.error("Error uploading file:", response.statusText);
      }
    } catch (error) {
      console.error("Upload failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div>
        <ToggleButtonGroup
          value={profileView}
          exclusive
          onChange={(event, newView) => setProfileView(newView)}
          aria-label="profile views"
          sx={{ marginBottom: 2 }}
        >
          <ToggleButton
            value="profile"
            aria-label="profile"
            className={toggleButton}
          >
            Profile
          </ToggleButton>
          <ToggleButton
            value="banck"
            aria-label="bank details"
            className={toggleButton}
          >
            Bank details
          </ToggleButton>
          <ToggleButton
            value="upi"
            aria-label="upi id"
            className={toggleButton}
          >
            UPI ID
          </ToggleButton>
        </ToggleButtonGroup>

        {profileView === "profile" && (
          <div className={`profile-form ${postsTheme}`}>
            {loading && (
              <div className="loading-overlay">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            )}
            <h2 style={{ margin: 0 }}>Profile</h2>
            <div style={{ marginBottom: "0.5rem", display: "grid" }}>
              <span>
                <Avatar
                  src={user.profileUrl !== "" ? user.profileUrl : profie}
                  sx={{ height: "150px", width: "150px", margin: "auto" }}
                  alt="Profile"
                  onClick={() => fileInputRef.current.click()}
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {profileUrl && (
                  <Button type="button" onClick={handleUpdate}>
                    Update
                  </Button>
                )}
              </span>

              <span
                style={{
                  overflowX: "hidden", // Change this to your desired color
                }}
              >
                Name:
                {updatevalue === "name" ? (
                  <>
                    <Input
                      placeholder="Enter Your Name"
                      onChange={(e) => setUpdateName(e.target.value)}
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                      }}
                    />
                    <Button type="button" onClick={handleUpdate}>
                      Update
                    </Button>
                  </>
                ) : (
                  <>
                    {user.name}
                    <Button
                      type="button"
                      onClick={() => handleEditName("name")}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </span>

              <span
                style={{
                  overflowX: "hidden", // Change this to your desired color
                }}
              >
                Email:
                {updatevalue === "email" ? (
                  <>
                    <Input
                      placeholder="Enter Your Email"
                      onChange={(e) => setUpdateEmail(e.target.value)}
                    />
                    <Button type="button" onClick={handleUpdate}>
                      Update
                    </Button>
                  </>
                ) : (
                  <>
                    {user.email}
                    <Button
                      type="button"
                      onClick={() => handleEditName("email")}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </span>

              <span
                style={{
                  overflowX: "hidden", // Change this to your desired color
                }}
              >
                Number:
                {updatevalue === "number" ? (
                  <>
                    <Input
                      type="number"
                      placeholder="Enter Your Number"
                      onChange={(e) => setUpdateNumber(e.target.value)}
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                      }}
                    />
                    <Button type="button" onClick={handleUpdate}>
                      Update
                    </Button>
                  </>
                ) : (
                  <>
                    {user.number}
                    <Button
                      type="button"
                      onClick={() => handleEditName("number")}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </span>
            </div>
          </div>
        )}

        {profileView === "banck" && (
          <div>
            <h2>Bank Details</h2>
            <BankDetailsDisplay
              handleOpenDialog={handleOpenDialog}
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSave={handleSaveDetails}
              bankDetails={user && user.bankDetails}
            />
            {/* Add bank details form or information here */}
          </div>
        )}

        {profileView === "upi" && (
          <div>
            <h2>UPI ID</h2>
            <UpiDetailsDisplay
              handleOpenDialog={handleOpenUpiDialog}
              open={upidialogOpen}
              onClose={() => setupiDialogOpen(false)}
              onSave={handleSaveUPIDetails}
              bankDetails={user && user.upi}
            />
            {/* Add UPI ID form or information here */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
