import "./analytics.css";
import { RiCoupon3Line } from "react-icons/ri";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa6";
import { useState } from "react";
import { Line, Doughnut } from "react-chartjs-2";
import { useUserAuth } from "../../Context/UseAuthContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement
);
const Analytics = () => {
  const [selectedOption, setSelectedOption] = useState("This Week");
  const { darkThemesetter } = useUserAuth();
  const ModeBasedColor = darkThemesetter
    ? "darktheme-dashboard"
    : "lighttheme-dashboard";
  const cardItemsInDashBoard = darkThemesetter
    ? "darktheme-dashboard-card"
    : "lighttheme-dashboard-card";

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className={`SectionDashBoardVendorAnalytics ${ModeBasedColor}`}>
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <h1>DashBoard</h1>
        <p>Welcome to your DashBoard , Represented your data</p>
      </div>

      <div className="SectionDashBoardVendorAnalyticsCards">
        <div className="SectionDashBoardVendorAnalyticsCards">
          <div
            className={`SectionDashBoardVendorAnalyticsCard ${cardItemsInDashBoard}`}
          >
            <div className="SectionDashBoardVendorAnalyticsCardInfo">
              <div>Total Sales</div>
              <div>
                <strong>{"$10800"}</strong>
              </div>
              <div>
                <span>{"$50"}</span> New Sales
              </div>
            </div>

            <div className="SectionDashBoardVendorAnalyticsCardImage">
              <RiCoupon3Line size={40} color={"#6440fb"} />
            </div>
          </div>

          <div
            className={`SectionDashBoardVendorAnalyticsCard ${cardItemsInDashBoard}`}
          >
            <div className="SectionDashBoardVendorAnalyticsCardInfo">
              <div>Total Courses</div>
              <div>
                <strong>{"$3759"}</strong>
              </div>
              <div>
                <span>{"$40"}</span> New Sales
              </div>
            </div>

            <div className="SectionDashBoardVendorAnalyticsCardImage">
              <FaRegPlayCircle size={40} color={"#6440fb"} />
            </div>
          </div>

          <div
            className={`SectionDashBoardVendorAnalyticsCard ${cardItemsInDashBoard}`}
          >
            <div className="SectionDashBoardVendorAnalyticsCardInfo">
              <div>Total Payments</div>
              <div>
                <strong>{"$10800"}</strong>
              </div>
              <div>
                <span>{"$50"}</span> New Sales
              </div>
            </div>

            <div className="SectionDashBoardVendorAnalyticsCardImage">
              <FaGraduationCap size={40} color={"#6440fb"} />
            </div>
          </div>

    
        </div>
      </div>

      <div className="SectionDashBoardVendorAnalyticsStatistics Graph-board">
        <div
          className={`SectionDashBoard-round-graph-one ${cardItemsInDashBoard}`}
        >
          <div className="SectionDashBoardVendorAnalyticsStatisticsGraphChart Graph-section-bar">
            <p>Earning Statistics</p>
            <div
              className={`SectionDashBoardVendorAnalyticsStatisticsGraphChartHeader${cardItemsInDashBoard}`}
            >
              {/* <p>Earning Statistics</p> */}
              <select
                className="SelectDataRange"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="This Week">This Week</option>
                <option value="Animation">Animation</option>
                <option value="Design">Design</option>
                <option value="Illustration">Illustration</option>
                <option value="Business">Business</option>
              </select>
            </div>
          </div>
          {/* <div className="analatics-dashbord-main"> */}
          <div className="sectionDashBoardVenodrAnalyticsStaticalGraph-Dought Graph-section-Dought">
            <div className="SectionDashBoardVendorAnalyticsStatisticsLineChart">
              <Line
                data={{
                  // x-axis label values
                  labels: [
                    "Jan",
                    "Feb",
                    "March",
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Nov",
                    "Dec",
                  ],
                  datasets: [
                    {
                      label: "",
                      // y-axis data plotting values
                      data: [
                        200, 300, 1300, 520, 2000, 350, 150, 800, 900, 500, 100,
                        799,
                      ],
                      fill: false,
                      borderWidth: 3,
                      backgroundColor: "white",
                      borderColor: "blue",
                      tension: 0.4, // Set the tension to make the line smoother
                      responsive: true,
                      pointRadius: 3, // Set pointRadius to 0 to remove the square points
                      pointHoverRadius: 6,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`SectionDashBoard-round-graph  ${cardItemsInDashBoard}`}
        >
          <div className="SectionDashBoardVendorAnalyticsStatisticsDoughnuttHeader Pie-chart-bar">
            <p>Earning Statistics</p>
            <select
              className="SelectDataRange"
              value={selectedOption}
              onChange={handleChange}
            >
              <option value="This Week">This Week</option>
              <option value="Animation">Animation</option>
              <option value="Design">Design</option>
              <option value="Illustration">Illustration</option>
              <option value="Business">Business</option>
            </select>
          </div>
          <div className="SectionDashBoardVendorAnalyticsStatisticsDoughnutChart Pie-chart-data">
            <Doughnut
              data={{
                labels: ["Direct", "Referral", "Organic"],
                datasets: [
                  {
                    data: [200, 300, 1300],
                    backgroundColor: ["#7153ef", "#6c4eeb", "#5837e4"],
                  },
                ],
              }}
              options={{
                cutout: 90,
              }}
            />
            <div className="DoughnutChartLabels">
              <p>
                <span></span>Organic
              </p>
              <p>
                <span></span>Direct
              </p>
              <p>
                <span></span>Referral
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
