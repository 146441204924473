import { Card, CardContent, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../../Context/UseAuthContext";
import "./singleProject.css";

const SingleProject = () => {
  const { darkThemesetter } = useUserAuth();
  const { projectID } = useParams();
  const [singlePost, setSinglePost] = useState(null);
  const singlecardStyles = darkThemesetter
    ? "singlepage-darkcolor"
    : "singlepage-lightcolor";

  const getSinglePost = () => {
    fetch(`https://smartuter.com/api/project/singleProject/${projectID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSinglePost(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSinglePost();
  }, []);
  return (
    <div className="single-post-container">
      {singlePost && (
        <Card>
          <CardContent className={`single-postcard ${singlecardStyles}`}>
            <div className="single-post-media">
              <img
                src={singlePost.OutputScreens}
                alt={singlePost.postName}
                className="single-studymaterial-image"
              />
            </div>
            <div>
              <Typography
                variant="h5"
                component="h3"
                className="single-project-head"
              >
                {singlePost.ProjectTitle}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className="single-project-description"
              >
                {singlePost.ProjectDescription}
              </Typography>
              <Typography variant="body1" component="p">
                {singlePost.TechnologiesUsed.map((e, i) => (
                  <span className="single-project-technologies" key={i}>
                    {e.text}
                  </span>
                ))}
              </Typography>

              {/* <Typography className="sub-admin-name">
                {singlePost.SubAdminName}
              </Typography> */}
              {/* <Typography className="sub-admin-number">
                {singlePost.SubAdminNumber}
              </Typography> */}
              <Typography
                variant="body1"
                component="p"
                className="single-project-price"
              >
                Price:- ₹{singlePost.Price}
              </Typography>
              <div>
                <Link
                  href={singlePost.UploadCodeFile}
                  className="single-post-link"
                >
                  zipLink
                </Link>
                <Link
                  href={singlePost.UploadDocumentFile}
                  className="single-post-link"
                >
                  DocumentLink
                </Link>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default SingleProject;
