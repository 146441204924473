import { Card, CardContent, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../../Context/UseAuthContext";
import "./SinglePost.css";

const SinglePost = () => {
  const { darkThemesetter } = useUserAuth();
  const { postID } = useParams();
  const [singlePost, setSinglePost] = useState(null);
  const singlecardStyles = darkThemesetter
    ? "singlepage-darkcolor"
    : "singlepage-lightcolor";

  const getSinglePost = () => {
    fetch(`https://smartuter.com/api/post/singlePost/${postID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSinglePost(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSinglePost();
  }, []);
  return (
    <div className="single-post-container">
      {singlePost && (
        <Card>
          <CardContent className={`single-post-card ${singlecardStyles}`}>
            <div className="single-post-media">
              <video
                controls
                width="400"
                height="400"
                autoPlay
                className="video-height"
              >
                <source src={singlePost.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="single-post-media">
              {/* <img src={singlePost.imageUrl} alt={singlePost.postName} /> */}
            </div>
            <span
              style={{
                border: "2px solid #315e85",
                padding: "10px",
                marginTop: "10px",
                marginRight: "10px",
                borderRadius: "8px",
              }}
            >
              {singlePost.mainCategory}
            </span>
            <span
              style={{
                border: "2px solid #315e85",
                padding: "10px",
                marginTop: "10px",
                marginRight: "10px",
                borderRadius: "8px",
              }}
            >
              {singlePost.subCategory}
            </span>
            <Typography
              variant="h5"
              component="h3"
              className="single-project-head"
            >
              {singlePost.postName}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className="single-project-description"
            >
              {singlePost.postMessage}
            </Typography>
            <Typography variant="body1" component="p">
              {singlePost.tags.map((e, i) => (
                <p className="single-project-technologies">{e.text}</p>
              ))}
            </Typography>

            {/* <Typography className="sub-admin-name">
              {singlePost.SubAdminName}
            </Typography> */}
            {/* <Typography className="sub-admin-number">
              {singlePost.SubAdminNumber}
            </Typography> */}

            {/* <button>{singlePost.approve?true:false}</button> */}
            <Typography
              variant="body1"
              component="p"
              className="single-project-price"
            >
              Price:- ₹{singlePost.price}
            </Typography>
            <div>
              <Link href={singlePost.zipUrl} className="single-post-link">
                zipLink
              </Link>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default SinglePost;
