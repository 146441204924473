import { Link } from "react-router-dom"
import { useUserAuth } from "../../Context/UseAuthContext"
import { Button } from "@mui/material"
import "./ProjectCardItems.css"

const ProjectCardItems = props=>{
    const {data,showPostForm,handleDeletepost} = props 
    const{
        ProjectTitle,
        OutputScreens,
        approve,
        _id
        } = data;
    const {darkThemesetter}=useUserAuth();
    const backgroundColor =  darkThemesetter ? "darktheme-view":"lighttheme-view"

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  }
  return text;
};
  

    return(
    <> 
    
         <div className={`card-item-css ${backgroundColor}`}>
                <img src={OutputScreens} alt={ProjectTitle} className="card-item-css-image"/>
            <div className="card-item-css-sub" >
                <Link to={`/project/${_id}`} className="card-item-css-Name" >
                      <p>{truncateText(ProjectTitle, 15)}</p>
                </Link>
                <div className="edit-delete-button-component">
                    <Button
                        variant="outlined"
                        onClick={() => showPostForm(data)}
                        disabled={approve}
                      >
                         Edit
                      </Button>
                    <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeletepost(data)}
                          disabled={approve}
                        >
                          Delete
                    </Button>
                 </div>
                </div>
            {/* </li> */}
        </div>

    </>
    )
}
export default ProjectCardItems