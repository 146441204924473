import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import './UpiDetailsDialog.css'
import { useUserAuth } from '../../../Context/UseAuthContext';

const UpiDetailsDialog = ({ open, onClose, onSave }) => {
  const [formValues, setFormValues] = useState({
    upiid: '',
  });

  const {darkThemesetter} = useUserAuth()

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";

  const inputColor = darkThemesetter ? '#f7f8fb' : 'black';



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
    const {upiid} = formValues
    if (!upiid) {
      alert("All fields are required.");
      return;
    }
    onSave(formValues);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={postsTheme}>
        <DialogTitle>Enter UPI ID </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="upiid"
            label="UPI ID"
            type="text"
            fullWidth
            InputProps={{
              style: { color: inputColor, paddingLeft: "10px" }, // Adjust the padding value as needed
            }}
            InputLabelProps={{
              style: { color: inputColor },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: inputColor,
                },
                paddingLeft: "10px",
              },
            }}
            value={formValues.upiid}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default UpiDetailsDialog;
