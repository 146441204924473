import { Card, CardContent, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAuth } from "../../../Context/UseAuthContext";
import "./SingleStudyMaterial.css";

const SingleStudyMaterial = () => {
  const { darkThemesetter } = useUserAuth();

  const { studymaterialID } = useParams();
  const [singleStudymaterial, setSingleStudymaterial] = useState(null);

  const getSingleStudymaterial = () => {
    fetch(
      `https://smartuter.com/api/studymaterial/singlestudymaterial/${studymaterialID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setSingleStudymaterial(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getSingleStudymaterial();
  }, []);
  const singlecardStyles = darkThemesetter
    ? "singlepage-darkcolor"
    : "singlepage-lightcolor";
  return (
    <div className="single-post-container">
      {singleStudymaterial && (
        <Card>
          <CardContent className={`single-postcard ${singlecardStyles}`}>
            <div className="single-post-media">
              <img
                src={singleStudymaterial.MaterialImage}
                alt={singleStudymaterial.MaterialName}
                className="single-studymaterial-image"
              />
            </div>
            <div>
              <span
                style={{
                  border: "2px solid #315e85",
                  padding: "10px",
                  marginTop: "10px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              >
                {singleStudymaterial.MaterialCategory}
              </span>
              <span
                style={{
                  border: "2px solid #315e85",
                  padding: "10px",
                  marginTop: "10px",
                  marginRight: "10px",
                  borderRadius: "8px",
                }}
              >
                {singleStudymaterial.MaterialName}
              </span>
              <Typography
                variant="body1"
                component="p"
                className="single-project-description"
              >
                {singleStudymaterial.MaterialDescription}
              </Typography>
              {/* <Typography className="sub-admin-name">
                {singleStudymaterial.SubAdminName}
              </Typography> */}
              {/* <Typography className="sub-admin-number">
                {singleStudymaterial.SubAdminNumber}
              </Typography> */}

              <Typography
                variant="body1"
                component="p"
                className="single-project-price"
              >
                Price:- ₹{singleStudymaterial.Price}
              </Typography>
              <Link
                href={singleStudymaterial.MaterialZip}
                className="single-post-link"
              >
                zipLink
              </Link>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default SingleStudyMaterial;
