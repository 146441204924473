import * as React from "react";
import { UilFilePlus } from "@iconscout/react-unicons";
import "../ProjectTable/Project.css";
import CardItem from "../cardItems/CardItem";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { ColorRing } from "react-loader-spinner";
import "./StudyMaterialTable.css";
import toast, { Toaster } from "react-hot-toast";

const StudyMaterilaTable = () => {
  const {
    fetchStudyMaterialData,
    StudyMaterial,
    getMainCategory,
    mainCategory,
    darkThemesetter,
  } = useUserAuth();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [imageURL, setImage] = useState(null);
  const [zipURL, setZipUrl] = useState(null);
  const [price, setPrice] = useState(0);
  const [singlepost, setsingelPost] = useState("");

  const [editName, setEditName] = useState(""); // State to store edited name
  const [editMessage, setEditMessage] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editZipUrl, setEditZipUrl] = useState(null);
  const [editImageUrl, setEditImgUrl] = useState(null);
  const [editMaterialCategory, setEditMaterialCategory] = useState(null);

  const [showform, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [studymaterialidToDelete, setstudymaterialidToDelete] = useState(null);
  const [mainCategoryselectedOption, setMainCategoryesSelectedOption] =
    useState(null);

  const [selectItem, setSelectedItem] = useState("All");
  const [categoryFilter, setCategoryFilter] = useState("Development");
  // ProgressBar
  const [imageUploadPogress, setImageUploadPogress] = useState(null);
  const [zipUploadPogress, setZipUploadPogress] = useState(null);
  const [pdfUploadPogress, setVideoPogress] = useState(null);
  const [uploadPogress, setUploadPogress] = useState(0);
  const [zipxhr, setzipxhr] = useState(null);
  const [imagexhr, setimagexhr] = useState(null);
  const [pdfxhr, setvideoxhr] = useState(null);

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const postsText = darkThemesetter ? "dark-text" : "light-text";

  const inputColor = darkThemesetter ? "#f7f8fb" : "black";

  const handleOptionChangeMainCategory = (e) => {
    setMainCategoryesSelectedOption(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];

    try {
      if (selectedFile.type.startsWith("image")) {
        // if (selectedFile.size < 1 * 1024 * 1024) {
        await handleUpload(selectedFile, "image");
        // } else {
        //   alert("Upload File Less Than 1 MB", {
        //     duration: 6000,
        //   });

        //   setLoading(false);
        // }
      } else if (selectedFile.type.startsWith("application/pdf")) {
        // if (selectedFile.size < 3 * 1024 * 1024) {
        await handleUpload(selectedFile, "pdf");
        // } else {
        //   alert("Upload File Less Than 3 MB", {
        //     duration: 6000,
        //   });

        //   setLoading(false);
        // }
      } else if (
        selectedFile.type === "application/zip" ||
        selectedFile.type === "application/x-zip-compressed"
      ) {
        // if (selectedFile.size < 5 * 1024 * 1024) {
        await handleUpload(selectedFile, "zip");
        // } else {
        //   alert("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });

        //   setLoading(false);
        // }
      } else {
        console.error("Unsupported file type");
        setLoading(false);
        toast.error("Unsupported file type");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unsupported file type");
      console.error("Error uploading file:", error.message);
    }
  };
  const handleUpload = async (file, fileType) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const xhr = new XMLHttpRequest();

      xhr.open(
        "POST",
        "https://smartuter.com/api/studymaterial/uploadstudymaterialfile"
      );

      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          // console.log(`Upload Progress: ${percentComplete.toFixed(2)}%`);
          setUploadPogress(percentComplete.toFixed(2));
          if (fileType === "image") {
            setImageUploadPogress(`${percentComplete.toFixed(2)}`);
            setimagexhr(xhr);
            return;
          }
          if (fileType === "zip") {
            setZipUploadPogress(`${percentComplete.toFixed(2)}`);
            setzipxhr(xhr);
            return;
          }

          if (fileType === "video") {
            setVideoPogress(`${percentComplete.toFixed(2)}`);
            setvideoxhr(xhr);
            return;
          }
        }
      });

      xhr.onload = function () {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          handleUploadSuccess(data.fileUrl, fileType);
        } else {
          console.error("Error uploading file:", xhr.statusText);
          toast.error("Upload failed");
        }
      };

      xhr.onerror = function () {
        console.error("Network error during upload");
        toast.error("Upload failed");
      };

      xhr.send(formData);
    } catch (error) {
      toast.error(error.message);
    }
    // const formData = new FormData();
    // formData.append("file", file);
    // const response = await fetch(
    //   "https://smartuter.com/api/studymaterial/uploadstudymaterialfile",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //     body: formData,
    //   }
    // );

    // if (response.ok) {
    //   const data = await response.json();
    //   handleUploadSuccess(data.fileUrl, fileType);
    // } else {
    //   console.error("Error uploading file:", response.statusText);
    //   throw new Error("Upload failed");
    // }
  };

  const handleUploadSuccess = (fileUrl, fileType) => {
    if (fileType === "image") {
      setImage(fileUrl);
      setLoading(false);
    } else if (fileType === "zip") {
      setZipUrl(fileUrl);
      setLoading(false);
    } else {
      console.error("Unsupported file type");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const payload = {
      MaterialName: name,
      MaterialDescription: message,
      MaterialCategory: mainCategoryselectedOption,
      MaterialZip: zipURL,
      MaterialImage: imageURL,
      Price: price,
      approve: false,
    };
    // Function to validate the payload
    const validatePayload = (payload) => {
      for (const key in payload) {
        if (payload[key] === null || payload[key] === "") {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
      }
      return true;
    };

    // Validate the payload before making the API call
    if (!validatePayload(payload)) {
      return; // Stop execution if validation fails
    }
    fetch("https://smartuter.com/api/studymaterial/create", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchStudyMaterialData();
      })
      .catch((err) => console.log(err));

    handleClose();
  };

  const showPostForm = (e) => {
    setsingelPost(e);
    setEditName(e.MaterialName); // Populate the name field with the selected e's name
    setEditMessage(e.MaterialDescription);
    setEditMaterialCategory(e.MaterialCategory);
    setEditImgUrl(e.MaterialImage);
    setEditZipUrl(e.MaterialZip);
    setEditPrice(e.Price);

    setShowForm(true);
  };

  const handleDeletepost = (e) => {
    const studymaterialID = e._id;
    setstudymaterialidToDelete(studymaterialID);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (studymaterialidToDelete) {
      fetch(
        `https://smartuter.com/api/studymaterial/delete/${studymaterialidToDelete}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          fetchStudyMaterialData();
        })
        .catch((err) => console.log(err));
    }
    // Close the confirmation popup
    setShowConfirmation(false);
  };

  const handleEditformSubmit = () => {
    const payload = {
      MaterialName: editName,
      MaterialDescription: editMessage,
      MaterialZip: zipURL ? zipURL : editZipUrl,
      MaterialImage: imageURL ? imageURL : editImageUrl,
      MaterialCategory: editMaterialCategory,
      Price: editPrice,
    };

    // Function to validate the payload
    const validatePayload = (payload) => {
      for (const key in payload) {
        if (payload[key] === null || payload[key] === "") {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
      }
      return true;
    };

    // Validate the payload before making the API call
    if (!validatePayload(payload)) {
      return; // Stop execution if validation fails
    }

    const studymaterialID = singlepost._id;
    fetch(`https://smartuter.com/api/studymaterial/update/${studymaterialID}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,

        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchStudyMaterialData();
        setShowForm(false);
      })
      .catch((err) => console.log(err));
  };

  const selectFilterApproved = (event) => {
    setSelectedItem(event.target.value);
  };

  const selectCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);
  };

  useEffect(() => {
    fetchStudyMaterialData();
    getMainCategory();
  }, []);

  const studymaterialData = StudyMaterial !== null && StudyMaterial.length > 0;

  let approvedData;
  let unApprovedData;
  if (studymaterialData) {
    approvedData = StudyMaterial.filter(
      (material) => material.approve === true
    );
    unApprovedData = StudyMaterial.filter(
      (material) => material.approve === false
    );
  }

  return (
    <div className="Table">
      <div className="recent-history">
        <div className="recent-history-heading-items-container">
          <h3 className="recent-history-heading">Recent StudyMaterial</h3>
          <div className="recent-dropdown">
            <select
              value={selectItem}
              className="select-item-element"
              onChange={selectFilterApproved}
            >
              <option value="All">All</option>
              <option value="Approved">Approved</option>
              <option value="UnApproved">UnApproved</option>
            </select>
            {/* <select
              className="select-item-element"
              onChange={selectCategoryFilter}
            >
              <option value="Development">Design</option>
              <option value="Bussinesss">Bussinesss</option>
              <option value="IT & Software">IT & Software</option>
              <option value="Design">Development</option>
              <option value="LIfe-Style">LIfe Style</option>
              <option value="Music">Music</option>
            </select> */}
          </div>

          <div>
            <Button variant="outlined" onClick={handleClickOpen}>
              <UilFilePlus /> Add
            </Button>
            {/* {loading && (
              <div className="loading-overlay">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            )} */}
            <Dialog open={open} onClose={handleClose}>
              <Toaster />
              <div className={postsTheme}>
                <DialogTitle>Submit Your Information</DialogTitle>
                <DialogContent>
                  <DialogContentText className={postsText}>
                    Please fill out the form below:
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Title"
                    type="text"
                    InputProps={{
                      style: { color: inputColor },
                    }}
                    InputLabelProps={{
                      style: { color: inputColor },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: inputColor,
                        },
                      },
                      marginBottom: "20px",
                    }}
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    id="message"
                    label="Description"
                    type="text"
                    fullWidth
                    InputProps={{
                      style: { color: inputColor },
                    }}
                    InputLabelProps={{
                      style: { color: inputColor },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: inputColor,
                        },
                      },
                      marginBottom: "20px",
                    }}
                    multiline
                    projects={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  Studymaterial output image{" "}
                  {imageUploadPogress && loading && (
                    <>
                      <progress value={imageUploadPogress} max="100" />{" "}
                      <button
                        onClick={() => (
                          imagexhr.abort(),
                          setImageUploadPogress(null),
                          setLoading(false),
                          setImage(null)
                        )}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                  <TextField
                    margin="dense"
                    id="image"
                    // label="Upload Image..."
                    type="file"
                    fullWidth
                    inputProps={{
                      accept: "image/*",
                    }}
                    InputProps={{
                      style: { color: inputColor },
                    }}
                    InputLabelProps={{
                      style: { color: inputColor },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: inputColor,
                        },
                      },
                      marginBottom: "20px",
                    }}
                    onChange={handleFileChange}
                    disabled={loading}
                  />
                  upload zip{" "}
                  {zipUploadPogress && loading && (
                    <>
                      <progress value={zipUploadPogress} max="100" />

                      <button
                        onClick={() => (
                          zipxhr.abort(),
                          setZipUploadPogress(null),
                          setLoading(false),
                          setZipUrl(null)
                        )}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                  <TextField
                    margin="dense"
                    id="file"
                    // label="Upload Zip..."
                    type="file"
                    fullWidth
                    inputProps={{
                      accept: ".zip",
                    }}
                    InputProps={{
                      style: { color: inputColor },
                    }}
                    InputLabelProps={{
                      style: { color: inputColor },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: inputColor,
                        },
                      },
                      marginBottom: "20px",
                    }}
                    onChange={handleFileChange}
                    disabled={loading}
                  />
                  MainCategory
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Main Category
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mainCategoryselectedOption || ""}
                      label="Main Category"
                      sx={{
                        color: inputColor,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                          "&:hover fieldset": {
                            borderColor: inputColor,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: inputColor,
                        },
                        marginBottom: "20px",
                      }}
                      style={{ width: "100%" }}
                      onChange={handleOptionChangeMainCategory}
                    >
                      {mainCategory &&
                        mainCategory.map((el, i) => (
                          <MenuItem
                            key={el._id}
                            value={el.category}
                            sx={{
                              color: darkThemesetter ? "black" : "black",
                              background: darkThemesetter
                                ? "rgb(88 85 85 / 87%)"
                                : "#f7f8fb",
                            }}
                          >
                            {el.category}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Price"
                    InputProps={{
                      style: { color: inputColor },
                    }}
                    InputLabelProps={{
                      style: { color: inputColor },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: inputColor,
                        },
                      },
                      marginBottom: "20px",
                    }}
                    type="number"
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  {loading ? (
                    <Button>in Progress {uploadPogress}%</Button>
                  ) : (
                    // <ColorRing
                    //   visible={true}
                    //   height="80"
                    //   width="80"
                    //   ariaLabel="color-ring-loading"
                    //   wrapperStyle={{}}
                    //   wrapperClass="color-ring-wrapper"
                    //   colors={[
                    //     "#e15b64",
                    //     "#f47e60",
                    //     "#f8b26a",
                    //     "#abbd81",
                    //     "#849b87",
                    //   ]}
                    // />
                    <Button onClick={handleSubmit}>Submit</Button>
                  )}
                </DialogActions>
              </div>
            </Dialog>
          </div>
        </div>
      </div>

      <ul className="list-of-card-items">
        {studymaterialData ? (
          <>
            {selectItem === "All" ? (
              StudyMaterial.length > 0 ? (
                StudyMaterial.map((item, index) => (
                  <CardItem
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="No Course Found"
                  />
                  <p className="no-course-found-text">No courses found.</p>
                </div>
              )
            ) : null}

            {selectItem === "Approved" ? (
              approvedData.length > 0 ? (
                approvedData.map((item, index) => (
                  <CardItem
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="No Course Found"
                  />
                  <p className="no-course-found-text">
                    No approved courses found.
                  </p>
                </div>
              )
            ) : null}

            {selectItem === "UnApproved" ? (
              unApprovedData.length > 0 ? (
                unApprovedData.map((item, index) => (
                  <CardItem
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="No Course"
                  />
                  <p className="no-course-found-text">
                    No unapproved courses found.
                  </p>
                </div>
              )
            ) : null}
          </>
        ) : null}
      </ul>
      <Dialog open={showform} onClose={() => setShowForm(false)}>
        <Toaster />
        <div className={postsTheme}>
          <DialogTitle>Edit Element</DialogTitle>
          <DialogContent>
            <DialogContentText>Edit the selected element:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Title"
              type="text"
              fullWidth
              value={editName}
              onChange={(e) => setEditName(e.target.value)} // Update editName state
            />
            <TextField
              margin="dense"
              id="editMessage"
              label="Description"
              type="text"
              fullWidth
              multiline
              projects={4}
              value={editMessage}
              onChange={(e) => setEditMessage(e.target.value)} // Update editMessage state
            />
            upload zip
            {zipUploadPogress && loading && (
              <>
                <progress value={zipUploadPogress} max="100" />

                <button
                  onClick={() => (
                    zipxhr.abort(),
                    setZipUploadPogress(null),
                    setLoading(false),
                    setEditZipUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="file"
              // label="Upload Zip..."
              type="file"
              fullWidth
              inputProps={{
                accept: ".zip",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            Studymaterial output image{" "}
            {imageUploadPogress && loading && (
              <>
                <progress value={imageUploadPogress} max="100" />{" "}
                <button
                  onClick={() => (
                    imagexhr.abort(),
                    setImageUploadPogress(null),
                    setLoading(false),
                    setEditImgUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="image"
              // label="Upload Image..."
              type="file"
              fullWidth
              inputProps={{
                accept: "image/*",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            MainCategory
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editMaterialCategory}
              label="Main Category"
              style={{ width: "100%" }}
              onChange={(e) => setEditMaterialCategory(e.target.value)}
            >
              {mainCategory &&
                mainCategory.map((el, i) => (
                  <MenuItem key={el._id} value={el.category}>
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Price"
              type="number"
              fullWidth
              value={editPrice}
              onChange={(e) => setEditPrice(e.target.value)} // Update editName state
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowForm(false)}>Cancel</Button>
            {loading ? (
              <Button>in Progress {uploadPogress}%</Button>
            ) : (
              // <ColorRing
              //   visible={true}
              //   height="80"
              //   width="80"
              //   ariaLabel="color-ring-loading"
              //   wrapperStyle={{}}
              //   wrapperClass="color-ring-wrapper"
              //   colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              // />
              <Button onClick={handleEditformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
export default StudyMaterilaTable;
