// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RightSide{
    display: flex;
    flex-direction: column;
    /* padding-top: 1rem; */
    width: 95%;
    /* justify-content: space-evenly; */
}


@media screen and (max-width: 1200px) {
    .RightSide{
        justify-content: flex-start;
        /* margin-top: 3rem; */
    }
}

@media screen and (max-width: 768px)
{
    .RightSide{
        width: 100%;
        margin-top: 0;
    }
    .RightSide>div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/RigtSide/RightSide.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,mCAAmC;AACvC;;;AAGA;IACI;QACI,2BAA2B;QAC3B,sBAAsB;IAC1B;AACJ;;AAEA;;IAEI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;AACJ","sourcesContent":[".RightSide{\n    display: flex;\n    flex-direction: column;\n    /* padding-top: 1rem; */\n    width: 95%;\n    /* justify-content: space-evenly; */\n}\n\n\n@media screen and (max-width: 1200px) {\n    .RightSide{\n        justify-content: flex-start;\n        /* margin-top: 3rem; */\n    }\n}\n\n@media screen and (max-width: 768px)\n{\n    .RightSide{\n        width: 100%;\n        margin-top: 0;\n    }\n    .RightSide>div{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
