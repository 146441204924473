import * as React from "react";
import { UilFilePlus } from "@iconscout/react-unicons";
import CourseCardItems from "../CourseCardItems/CourseCardItems";
import "./Table.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { ColorRing } from "react-loader-spinner";
import { WithContext as ReactTags } from "react-tag-input";
import toast, { Toaster } from "react-hot-toast";

// const makeStyle = (status) => {
//   if (status === "Approved") {
//     return {
//       background: "rgb(145 254 159 / 47%)",
//       color: "green",
//     };
//   } else if (status === "Pending") {
//     return {
//       background: "#ffadad8f",
//       color: "red",
//     };
//   } else {
//     return {
//       background: "#59bfff",
//       color: "white",
//     };
//   }
// };

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
export default function BasicTable() {
  // const isSmallDevice = window.innerWidth <= 766;
  const {
    fetchData,
    rows,
    // getCourseCategory,
    getMainCategory,
    getSubCategory,
    // courseCategory,
    mainCategory,
    subCategory,
    darkThemesetter,
  } = useUserAuth();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [imageURL, setImage] = useState(null);
  const [videoURL, setVideoUrl] = useState(null);
  const [zipURL, setZipUrl] = useState(null);
  const [price, setPrice] = useState(0);
  const [singlepost, setsingelPost] = useState("");
  const [editName, setEditName] = useState(""); // State to store edited name
  const [editMessage, setEditMessage] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editTags, setEditTags] = useState(null);
  const [editMainCategory, setEditMainCategory] = useState(null);
  const [editSubCategory, setEditSubCategory] = useState(null);
  // const [editCourseCategory, setEditCourseCategory] = useState(null);

  const [editVideoUrl, setEditVideoUrl] = useState(null);
  const [editZipUrl, setEditZipUrl] = useState(null);
  const [editImageUrl, setEditImageUrl] = useState(null);
  const [showform, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [mainCategoryselectedOption, setMainCategoryesSelectedOption] =
    useState(null);

  const [subCategoryselectedOption, setSubCategoryesSelectedOption] =
    useState(null);
  // const [courseCategoryselectedOption, setCourseCategoryesSelectedOption] =
  //   useState(null);
  // ProgressBar
  const [imageUploadPogress, setImageUploadPogress] = useState(null);
  const [zipUploadPogress, setZipUploadPogress] = useState(null);
  const [pdfUploadPogress, setVideoPogress] = useState(null);
  const [uploadPogress, setUploadPogress] = useState(0);
  const [zipxhr, setzipxhr] = useState(null);
  const [imagexhr, setimagexhr] = useState(null);
  const [pdfxhr, setvideoxhr] = useState(null);
  // const [smallDevice, setIsSmallDevice] = useState(window.innerWidth <= 768);
  const [approveCourseFilter, setApproveCourseFilter] = useState("All");

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const postsText = darkThemesetter ? "dark-text" : "light-text";

  const inputColor = darkThemesetter ? "#f7f8fb" : "black";

  // useEffect(() => {
  //   // const handleResize = () => {
  //   //   setIsSmallDevice(window.innerWidth <= 768);
  //   // };

  //   // window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const handleOptionChangeMainCategory = (e) => {
    setMainCategoryesSelectedOption(e.target.value);
  };
  const handleOptionChangeSubCategory = (e) => {
    setSubCategoryesSelectedOption(e.target.value);
  };
  // const handleOptionChangeCourseCategory = (e) => {
  //   setCourseCategoryesSelectedOption(e.target.value);
  // };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    try {
      if (selectedFile.type.startsWith("image")) {
        // if (selectedFile.size < 1 * 1024 * 1024) {
        await handleUpload(selectedFile, "image");
        // } else {
        //   alert("Upload File Less Than 1 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (selectedFile.type.startsWith("video")) {
        // if (selectedFile.size < 5 * 1024 * 1024) {
        await handleUpload(selectedFile, "video");
        // } else {
        //   alert("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (
        selectedFile.type === "application/zip" ||
        selectedFile.type === "application/x-zip-compressed"
      ) {
        // if (selectedFile.size < 5 * 1024*1024) {
        await handleUpload(selectedFile, "zip");
        // } else {
        //   alert("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else {
        console.error("Unsupported file type", selectedFile);
        setLoading(false);

        toast.error("Unsupported file type");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unsupported file type");

      console.error("Error uploading file:", error.message);
    }
  };

  const handleUpload = async (file, fileType) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const xhr = new XMLHttpRequest();

      xhr.open("POST", "https://smartuter.com/api/post/uploadimage");

      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          // console.log(`Upload Progress: ${percentComplete.toFixed(2)}%`);
          setUploadPogress(percentComplete.toFixed(2));
          if (fileType === "image") {
            setZipUploadPogress(null);
            setVideoPogress(null);
            setImageUploadPogress(`${percentComplete.toFixed(2)}`);

            setimagexhr(xhr);
            return;
          }
          if (fileType === "zip") {
            setVideoPogress(null);
            setImageUploadPogress(null);
            setZipUploadPogress(`${percentComplete.toFixed(2)}`);

            setzipxhr(xhr);
            return;
          }

          if (fileType === "video") {
            setImageUploadPogress(null);
            setZipUploadPogress(null);
            setVideoPogress(`${percentComplete.toFixed(2)}`);

            setvideoxhr(xhr);
            return;
          }
        }
      });

      xhr.onload = function () {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          handleUploadSuccess(data.fileUrl, fileType);
        } else {
          console.error("Error uploading file:", xhr.statusText);
          toast.error("Upload failed");
        }
      };

      xhr.onerror = function () {
        console.error("Network error during upload");
        toast.error("Upload failed");
      };

      xhr.send(formData);
    } catch (error) {
      toast.error(error.message);
    }

    // const formData = new FormData();
    // formData.append("file", file);

    // const response = await fetch("https://smartuter.com/api/post/uploadimage", {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     // Add other necessary headers
    //   },
    //   body: formData,
    // });

    // if (response.ok) {
    //   const data = await response.json();
    //   handleUploadSuccess(data.fileUrl, fileType);
    // } else {
    //   console.error("Error uploading file:", response.statusText);
    //   throw new Error("Upload failed");
    // }
  };

  const handleUploadSuccess = (fileUrl, fileType) => {
    if (fileType === "image") {
      setImage(fileUrl);
      setLoading(false);
    } else if (fileType === "video") {
      setVideoUrl(fileUrl);
      setLoading(false);
    } else if (fileType === "zip") {
      setZipUrl(fileUrl);
      setLoading(false);
    } else {
      console.error("Unsupported file type");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const payload = {
      postName: name,
      postMessage: message,
      imageUrl: imageURL,
      videoUrl: videoURL,
      zipUrl: zipURL,
      mainCategory: mainCategoryselectedOption,
      subCategory: subCategoryselectedOption,
      // courseCategory: courseCategoryselectedOption,
      tags: tags,
      price: price,
      approve: false,
    };

    // Function to validate the payload
    const validatePayload = (payload) => {
      for (const key in payload) {
        if (payload[key] === null || payload[key] === "") {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
      }
      return true;
    };

    // Validate the payload before making the API call
    if (!validatePayload(payload)) {
      return; // Stop execution if validation fails
    }
    fetch("https://smartuter.com/api/post/create", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchData();
      })
      .catch((err) => console.log(err));

    handleClose();
  };

  const showPostForm = (e) => {
    setsingelPost(e);
    setEditName(e.postName); // Populate the name field with the selected e's name
    setEditMessage(e.postMessage);
    setEditTags(e.tags);
    setEditMainCategory(e.mainCategory);
    setEditSubCategory(e.subCategory);
    // setEditCourseCategory(e.courseCategory);

    setEditVideoUrl(e.videoUrl);
    setEditZipUrl(e.zipUrl);
    setEditImageUrl(e.imageUrl);
    setEditPrice(e.price);
    setShowForm(true);
  };

  const handleDeletepost = (e) => {
    const postID = e._id;
    setPostIdToDelete(postID);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (postIdToDelete) {
      fetch(`https://smartuter.com/api/post/delete/${postIdToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          fetchData();
        })
        .catch((err) => console.log(err));
    }
    // Close the confirmation popup
    setShowConfirmation(false);
  };

  const handleEditformSubmit = () => {
    const payload = {
      postMessage: editMessage,
      postName: editName,
      imageUrl: imageURL ? imageURL : editImageUrl,
      videoUrl: videoURL ? videoURL : editVideoUrl,
      zipUrl: zipURL ? zipURL : editZipUrl,
      price: editPrice,
      mainCategory: editMainCategory,
      subCategory: editSubCategory,
      // courseCategory: editCourseCategory,
      tags: editTags,
    };

    // Function to validate the payload
    const validatePayload = (payload) => {
      for (const key in payload) {
        if (payload[key] === null || payload[key] === "") {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
        if (Array.isArray(payload[key]) && payload[key].length === 0) {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
      }
      return true;
    };

    // Validate the payload before making the API call
    if (!validatePayload(payload)) {
      return; // Stop execution if validation fails
    }

    const postID = singlepost._id;
    fetch(`https://smartuter.com/api/post/update/${postID}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,

        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchData();
        setShowForm(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
    getMainCategory();
    getSubCategory();
    // getCourseCategory();
  }, []);

  const [tags, setTags] = useState([]);
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  // const handleTagClick = (index) => {
  //   console.log("The tag at index " + index + " was clicked");
  // };

  const handleEditDelete = (i) => {
    setEditTags(editTags.filter((tag, index) => index !== i));
  };

  const handleEditAddition = (tag) => {
    setEditTags([...editTags, tag]);
  };

  const handleEditDrag = (tag, currPos, newPos) => {
    const newTags = editTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setEditTags(newTags);
  };

  // const handleTagEditClick = (index) => {
  //   console.log("The tag at index " + index + " was clicked");
  // };

  const approveFilterCourse = (event) => {
    setApproveCourseFilter(event.target.value);
  };

  const isCourseData = rows !== null && rows.length > 0;
  let approveCourse;
  let unApprovedCoruse;
  if (isCourseData) {
    approveCourse = rows.filter((item) => item.approve === true);
    unApprovedCoruse = rows.filter((item) => item.approve === false);
  }
  return (
    <div className="Table">
      <div className="recent-history">
        <div className="recent-history-heading-items-container">
          <h3 className="recent-history-heading">Recent Course</h3>
          <div className="recent-history-select-items">
            <select
              value={approveCourseFilter}
              onChange={approveFilterCourse}
              className="select-item-element"
            >
              <option value="All">All</option>
              <option value="Approved">Approved</option>
              <option value="UnApproved">UnApproved</option>
            </select>

            <div>
              <Button variant="outlined" onClick={handleClickOpen}>
                <UilFilePlus /> Add
              </Button>
              {/* {loading && (
                <div className="loading-overlay">
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )} */}
              <Dialog open={open} onClose={handleClose}>
                <Toaster />
                <div className={postsTheme}>
                  <DialogTitle>Submit Your Information</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please fill out the form below:
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Title"
                      type="text"
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {/* <TextField
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              /> */}
                    <TextField
                      margin="dense"
                      id="message"
                      label="Description"
                      type="text"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      multiline
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    Tags
                    <ReactTags
                      tags={tags}
                      // suggestions={suggestions}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      // handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                      classNames={{
                        tagInputField: "ReactTags__tagInput",
                        tags: "ReactTags__tags",
                        tag: "ReactTags__tag",
                        remove: "ReactTags__remove",
                      }}
                    />
                    Course output image{" "}
                    {imageUploadPogress && loading && (
                      <>
                        <progress value={imageUploadPogress} max="100" />{" "}
                        <button
                          onClick={() => (
                            imagexhr.abort(),
                            setImageUploadPogress(null),
                            setLoading(false),
                            setImage(null)
                          )}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <TextField
                      margin="dense"
                      id="image"
                      // label="Upload Image..."
                      type="file"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      inputProps={{
                        accept: "image/*",
                      }}
                      onChange={handleFileChange}
                      disabled={loading}
                    />
                    upload video{" "}
                    {pdfUploadPogress && loading && (
                      <>
                        <progress value={pdfUploadPogress} max="100" />

                        <button
                          onClick={() => (
                            pdfxhr.abort(),
                            setVideoPogress(null),
                            setLoading(false),
                            setVideoUrl(null)
                          )}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <TextField
                      margin="dense"
                      id="video"
                      // label="Upload Video..."
                      type="file"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      inputProps={{
                        accept: "video/*",
                      }}
                      onChange={handleFileChange}
                      disabled={loading}
                    />
                    upload zip{" "}
                    {zipUploadPogress && loading && (
                      <>
                        <progress value={zipUploadPogress} max="100" />

                        <button
                          onClick={() => (
                            zipxhr.abort(),
                            setZipUploadPogress(null),
                            setLoading(false),
                            setZipUrl(null)
                          )}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <TextField
                      margin="dense"
                      id="file"
                      // label="Upload Zip..."
                      type="file"
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      fullWidth
                      inputProps={{
                        accept: ".zip",
                      }}
                      onChange={handleFileChange}
                      disabled={loading}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Main Category
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mainCategoryselectedOption || ""}
                        label="Main Category"
                        sx={{
                          color: inputColor,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: inputColor,
                            },
                            "&:hover fieldset": {
                              borderColor: inputColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: inputColor,
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: inputColor,
                          },
                          marginBottom: "20px",
                        }}
                        style={{ width: "100%" }}
                        onChange={handleOptionChangeMainCategory}
                      >
                        {mainCategory &&
                          mainCategory.map((el, i) => (
                            <MenuItem
                              key={el._id}
                              value={el.category}
                              sx={{
                                color: darkThemesetter ? "black" : "black",
                                background: darkThemesetter
                                  ? "rgb(88 85 85 / 87%)"
                                  : "#f7f8fb",
                              }}
                            >
                              {el.category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Sub Category
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subCategoryselectedOption || ""}
                        sx={{
                          color: inputColor,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: inputColor,
                            },
                            "&:hover fieldset": {
                              borderColor: inputColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: inputColor,
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: inputColor,
                          },
                          marginBottom: "20px",
                        }}
                        label="Sub Category"
                        style={{ width: "100%" }}
                        onChange={handleOptionChangeSubCategory}
                      >
                        {subCategory &&
                          subCategory.map((el, i) => (
                            <MenuItem
                              key={el._id}
                              value={el.category}
                              sx={{
                                color: darkThemesetter ? "black" : "black",
                                background: darkThemesetter
                                  ? "rgb(88 85 85 / 87%)"
                                  : "#f7f8fb",
                              }}
                            >
                              {el.category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {/* ChildCategory
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={courseCategoryselectedOption || ""}
                      sx={{
                        color: inputColor,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                          "&:hover fieldset": {
                            borderColor: inputColor,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: inputColor,
                        },
                      }}
                      label="Course Category"
                      style={{ width: "100%" }}
                      onChange={handleOptionChangeCourseCategory}
                    >
                      {courseCategory &&
                        courseCategory.map((el, i) => (
                          <MenuItem
                            key={el._id}
                            value={el.category}
                            sx={{
                              color: darkThemesetter ? "black" : "black",
                              background: darkThemesetter
                                ? "rgb(88 85 85 / 87%)"
                                : "#f7f8fb",
                            }}
                          >
                            {el.category}
                          </MenuItem>
                        ))}
                    </Select> */}
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Price"
                      type="number"
                      inputProps={{
                        accept: ".zip",
                      }}
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                      }}
                      fullWidth
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    {/* <TextField
      type="file"
      accept="video/*"
      onChange={handleVideoFileChange}
    />

    <TextField
      type="file"
      accept=".zip"
      onChange={handleZipFileChange}
    /> */}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {loading ? (
                      <Button>in Progress {uploadPogress}%</Button>
                    ) : (
                      // <ColorRing
                      //   visible={true}
                      //   height="80"
                      //   width="80"
                      //   ariaLabel="color-ring-loading"
                      //   wrapperStyle={{}}
                      //   wrapperClass="color-ring-wrapper"
                      //   colors={[
                      //     "#e15b64",
                      //     "#f47e60",
                      //     "#f8b26a",
                      //     "#abbd81",
                      //     "#849b87",
                      //   ]}
                      // />
                      <Button onClick={handleSubmit}>Submit</Button>
                    )}
                  </DialogActions>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      {/* <TableContainer component={Paper} className="table-container" style={{}}>
        <Table sx={{ minWidth: 0 }} aria-label="simple table">
          <TableHead
            style={{ position: "sticky", top: 0, zIndex:1, backgroundColor: "white" }}
          >
            <TableRow>
              <TableCell>APPROVAL</TableCell>
              <TableCell>Title</TableCell>
              {!isSmallDevice && <TableCell align="left">EDIT</TableCell>}
              {!isSmallDevice && <TableCell align="left">DELETE</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody className="tbody" style={{ color: "white" }}>
            {rows !== null &&
              rows.length > 0 &&
              rows.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                   <div className="large-devices">
                  <TableCell component={"th"} scope="row">
                    {row.approve ? (
                      <Button variant="contained" color="success">
                        APPROVED
                      </Button>
                    ) : (
                      <Button variant="contained" color="error">
                        NOT APPROVED
                      </Button>
                    )}
                  </TableCell>
                  </div>
                  <div className="small-devices">
                  <TableCell component={"th"} scope="row">
                    {row.approve ? (
                      <Button variant="contained" color="success">
                        <UilCheckCircle/>
                      </Button>
                    ) : (
                      <Button variant="contained" color="error">
                        <UilTimesCircle/>
                      </Button>
                    )}
                  </TableCell>
                  </div>
                  <TableCell component="th" scope="row">
                    <Link to={`/post/${row._id}`}>{row.postName}</Link>
                  </TableCell>
                 

      {!isSmallDevice && (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() => showPostForm(row)}
                        disabled={row.approve}
                      >
                        <UilEditAlt /> Edit
                      </Button>
                    </TableCell>
                  )}
                    {!isSmallDevice && (
                      <TableCell align="left">
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeletepost(row)}
                          disabled={row.approve}
                        >
                          <UilTrashAlt /> Delete
                        </Button>
                      </TableCell>
                    )}

                  
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      {/* <ul className="list-of-card-items">
      { isCourseData && approveCourseFilter==="All" && 
        rows.map((item,index)=>(
          <CourseCardItems data={item} key={index}/>
          
        ))
        }
        { isCourseData && approveCourseFilter==="Approved" && 
        approveCourse.map((item,index)=>(
          <CourseCardItems data={item} key={index}/>
          
        ))
        }
        {isCourseData && approveCourseFilter === "unApproved" &&
        unApprovedCoruse.map((item,index)=>
          <CourseCardItems data={item} key={index}/>
          
        )
        }
      </ul> */}
      <ul className="list-of-card-items">
        {isCourseData ? (
          <>
            {approveCourseFilter === "All" ? (
              rows.length > 0 ? (
                rows.map((item, index) => (
                  <CourseCardItems
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="notfound"
                  />
                  <p className="no-course-found-text">No courses found.</p>
                </div>
              )
            ) : null}

            {approveCourseFilter === "Approved" ? (
              approveCourse.length > 0 ? (
                approveCourse.map((item, index) => (
                  <CourseCardItems
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="notfound"
                  />
                  <p className="no-course-found-text">
                    No approved courses found.
                  </p>
                </div>
              )
            ) : null}

            {approveCourseFilter === "UnApproved" ? (
              unApprovedCoruse.length > 0 ? (
                unApprovedCoruse.map((item, index) => (
                  <CourseCardItems
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="notfound"
                  />
                  <p className="no-course-found-text">
                    No unapproved courses found.
                  </p>
                </div>
              )
            ) : null}
          </>
        ) : (
          <div className="no-course-found-container">
            <img
              src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
              className="no-course-found-img"
              alt="notfound"
            />
            <p className="no-course-found-text">No courses found.</p>
          </div>
        )}
      </ul>

      <Dialog open={showform} onClose={() => setShowForm(false)}>
        <Toaster />
        <div className={postsTheme}>
          <DialogTitle>Edit Course</DialogTitle>
          <DialogContent>
            <DialogContentText className={postsText}>
              Edit the selected course:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Title"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              value={editName}
              onChange={(e) => setEditName(e.target.value)} // Update editName state
            />
            <TextField
              margin="dense"
              id="editMessage"
              label="Description"
              type="text"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              multiline
              rows={4}
              value={editMessage}
              onChange={(e) => setEditMessage(e.target.value)} // Update editMessage state
            />
            Tags
            <ReactTags
              tags={editTags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleEditDelete}
              handleAddition={handleEditAddition}
              handleDrag={handleEditDrag}
              // handleTagClick={handleTagEditClick}
              inputFieldPosition="bottom"
              autocomplete
            />
            Course output image{" "}
            {imageUploadPogress && loading && (
              <>
                <progress value={imageUploadPogress} max="100" />{" "}
                <button
                  onClick={() => (
                    imagexhr.abort(),
                    setImageUploadPogress(null),
                    setLoading(false),
                    setEditImageUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="image"
              // label="Upload Image..."
              type="file"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              inputProps={{
                accept: "image/*",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            upload video{" "}
            {pdfUploadPogress && loading && (
              <>
                <progress value={pdfUploadPogress} max="100" />

                <button
                  onClick={() => (
                    pdfxhr.abort(),
                    setVideoPogress(null),
                    setLoading(false),
                    setEditVideoUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="video"
              // label="Upload Video..."
              type="file"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              inputProps={{
                accept: "video/*",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            upload zip
            {zipUploadPogress && loading && (
              <>
                <progress value={zipUploadPogress} max="100" />

                <button
                  onClick={() => (
                    zipxhr.abort(),
                    setZipUploadPogress(null),
                    setLoading(false),
                    setEditZipUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="file"
              // label="Upload Zip..."
              type="file"
              fullWidth
              inputProps={{
                accept: ".zip",
              }}
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editMainCategory}
              label="Main Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                  "&:hover fieldset": {
                    borderColor: inputColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditMainCategory(e.target.value)}
            >
              {mainCategory &&
                mainCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editSubCategory}
              label="Sub Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                  "&:hover fieldset": {
                    borderColor: inputColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditSubCategory(e.target.value)}
            >
              {subCategory &&
                subCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            {/* CourseCategory
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editCourseCategory}
              label="Course Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                  "&:hover fieldset": {
                    borderColor: inputColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditCourseCategory(e.target.value)}
            >
              {courseCategory &&
                courseCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select> */}
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Price"
              type="number"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
              }}
              fullWidth
              value={editPrice}
              onChange={(e) => setEditPrice(e.target.value)} // Update editName state
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowForm(false)}>Cancel</Button>
            {loading ? (
              <Button>in Progress {uploadPogress}%</Button>
            ) : (
              // <ColorRing
              //   visible={true}
              //   height="80"
              //   width="80"
              //   ariaLabel="color-ring-loading"
              //   wrapperStyle={{}}
              //   wrapperClass="color-ring-wrapper"
              //   colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              // />
              <Button onClick={handleEditformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
