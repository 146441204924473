// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Updates{
    
    margin-top: 5px;
}

.update{
    display: flex;
    gap: 0.5rem;
}

.update>img{
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    margin-top: 1vh;
}

.noti>div>span:nth-of-type(1){
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Updates/Updates.css"],"names":[],"mappings":";AACA;;IAEI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.Updates{\n    \n    margin-top: 5px;\n}\n\n.update{\n    display: flex;\n    gap: 0.5rem;\n}\n\n.update>img{\n    width: 3.2rem;\n    height: 3.2rem;\n    border-radius: 50%;\n    margin-top: 1vh;\n}\n\n.noti>div>span:nth-of-type(1){\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
