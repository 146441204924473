

import React, { useState, useEffect } from "react";
import "./Sidebar.css";
// import Logo from "../../imgs/logo.png";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "../../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";

const Sidebar = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const { setUser, darkThemesetter } = useUserAuth();
  const [selected, setSelected] = useState(0);
  
  const [expanded, setExpanded] = useState(window.innerWidth > 768); // Set initial state based on screen size
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);



  const backgroundColor = darkThemesetter ? "dark-sidebar" : "light-sidebar";

  const handleToggleSidebar = () => {
    setExpanded(!expanded);
  };

  const handleMenuItemClick = (index, path) => {
    setSelected(index);
    localStorage.setItem("selected_item",index)
    navigate(path);
    if (isSmallScreen) {
      setExpanded(false); // Hide sidebar only on small screens
    }
  };

  useEffect(() => {
    const storedIndex = localStorage.getItem("selected_item")
    if(storedIndex !== null){
      setSelected(parseInt(storedIndex))
    }
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        // Expand sidebar when transitioning to larger screen
        setExpanded(true);
      }
    };
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="bars"
        style={{
          left: "5%",
          top: "1.5%",
          background: expanded ? "#315e85" : "#220138",
        }}
        onClick={handleToggleSidebar}
      >
        <UilBars />
      </div>
      <motion.div
        className={`sidebar ${backgroundColor}`}
        animate={{ left: expanded ? "0" : "-60%" }}
        transition={{ duration: 0.1 }} 
      >
        <div className="menu">
          {SidebarData.map((item, index) => (
            <div
              className={selected === index ? "menuItem active" : "menuItem"}
              key={index}
              onClick={() => handleMenuItemClick(index, item.path)}
            >
              <item.icon />
              <span>{item.heading}</span>
            </div>
          ))}
          {/* Sign out icon */}
          <div className="menuItem" onClick={() => {
                localStorage.clear();
                navigate("/login");
                setUser("");
              }}>
            <UilSignOutAlt />
            <span>Logout</span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;

