import React from 'react'
import { useUserAuth } from '../Context/UseAuthContext'
import { Navigate } from 'react-router-dom';
import toast from "react-hot-toast";

const PrivateRotes = ({children}) => {
    const {user,token}=useUserAuth();
 if(!token){
    return<Navigate to={"/login"} />;
 }else if(user.isVerified===false){
   localStorage.removeItem("token");
   toast.error("You are not Verified User");
   return<Navigate to={"/login"}/>;
 }
 return children;
}

export default PrivateRotes;