import React from 'react'
import { useUserAuth } from '../../Context/UseAuthContext';
import StudyMaterilaTable from '../../components/StudyMaterial/StudyMaterilaTable'
import "./StudyMaterial.css"

const StudyMaterial = () => {
  const {darkThemesetter}=useUserAuth();
  const mainBackgroundColor = darkThemesetter  ? "Study-darktheme-color" : "Study-lighttheme-color"
  
  return (
    <div className={mainBackgroundColor}>
      {/* <h1 className={`create-course-heading ${color}`}>Study Material</h1> */}
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <h1>Study Materials</h1>
        <p>Creating Comprehensive Studymaterial for Smartuter</p>
        <hr />
      </div>
      <StudyMaterilaTable />
    </div>
  );
}

export default StudyMaterial