import React from "react";
import "./contentPage.css";
import { AllRoutes } from "../AllRoutes";
import { Toaster } from "react-hot-toast";


const ContentPage = () => {
  return (
    <div className="contentPage">
      <Toaster   />
      <div className="contentpage-image">
        <div className="contactpage-text">
          <p>Start learning for free</p>
          <h1>Explore Your Creativity With Thousands Of Online Classes.</h1>
        </div>
        <div className="contentpage-login">
          <AllRoutes />
        </div>
      </div>
    </div>
  );
};

export default ContentPage;
