import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [socket, updateSocket] = useState("");

  const [user, setUser] = useState("");

  const [rows, setRows] = useState(null);
  const [projects, setProjectdata] = useState(null);
  const [StudyMaterial, setStudyMaterial] = useState(null);
  const [courseCategory, setCourseCategoryes] = useState(null);
  const [subCategory, setSubCategoryes] = useState(null);
  const [mainCategory, setMainCategoryes] = useState(null);
  const [darkThemesetter, setDarkTheme] = useState(false);
  const [loading, setLoadingl] = useState(false);
  //Notifications
  const [notification, setNotification] = useState(null);

  const darkTheme = () => {
    setDarkTheme(!darkThemesetter);
  };

  useEffect(() => {}, []);
  const getUserData = () => {
    setLoadingl(true);
    fetch("https://smartuter.com/api/subadmin/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUser(res);
        setLoadingl(false);
      })
      .catch((err) => {
        setLoadingl(false);
        toast.error(`${err.message}`);

        localStorage.removeItem("token");
        navigate("/login");
      });
  };

  const fetchData = async () => {
    try {
      const response = await fetch("https://smartuter.com/api/post/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const jsonData = await response.json();

        setRows(jsonData);
      } else {
        console.error("Error fetching data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getMainCategory = () => {
    fetch("https://smartuter.com/api/category/main", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setMainCategoryes(res);
      })
      .catch((err) => console.log(err));
  };
  const getSubCategory = () => {
    fetch("https://smartuter.com/api/category/sub", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setSubCategoryes(res);
      })
      .catch((err) => console.log(err));
  };
  const getCourseCategory = () => {
    fetch("https://smartuter.com/api/category/course", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setCourseCategoryes(res);
      })
      .catch((err) => console.log(err));
  };

  const fetchProjectData = async () => {
    try {
      const response = await fetch("https://smartuter.com/api/project/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const jsonData = await response.json();

        setProjectdata(jsonData);
      } else {
        console.error("Error fetching data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchStudyMaterialData = async () => {
    try {
      const response = await fetch("https://smartuter.com/api/studymaterial/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const jsonData = await response.json();

        setStudyMaterial(jsonData);
      } else {
        console.error("Error fetching data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Notification
  const getNotification = async () => {
    try {
      const res = await axios.get(
        "https://smartuter.com/api/notification/admin/"
      );
      //  const audio=new Audio(notifay);
      //  audio.play();
      setNotification(res.data.getNotificationData);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (token) {
      getUserData();

      // Define the timeout duration (24 hours in milliseconds)
      const timeoutDuration = 86400000; // 24 hours

      // Set a timeout to remove the item after 24 hours
      const timeoutId = setTimeout(() => {
        // Remove item from local storage
        localStorage.removeItem("token");
      }, timeoutDuration);

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [token]);
  useEffect(() => {
    if (token) {
      fetchData();
      getMainCategory();
      getSubCategory();
      getCourseCategory();
      fetchProjectData();
      fetchStudyMaterialData();
    }
  }, [token, socket]);
  return (
    <userAuthContext.Provider
      value={{
        user,
        setUser,
        getUserData,
        token,
        rows,
        setRows,
        fetchData,
        getMainCategory,
        getCourseCategory,
        getSubCategory,
        courseCategory,
        subCategory,
        mainCategory,
        projects,
        setProjectdata,
        fetchProjectData,
        StudyMaterial,
        setStudyMaterial,
        fetchStudyMaterialData,
        socket,
        updateSocket,
        darkTheme,
        darkThemesetter,
        loading,
        navigate,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
