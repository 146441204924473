import RightSide from "../RigtSide/RightSide"
import LogoNormalMode from "../../imgs/LogoNormalMode.png";
import WhiteLogo from "../../imgs/WhiteLogo.png"
import { LuSunMoon } from "react-icons/lu";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import { useUserAuth } from "../../Context/UseAuthContext";
import "./NavbarDashboard.css"
import { useState } from "react";
import Notification from "../Notification/Notification";


const NavbarDashboard = ()=>{ 
    const {darkTheme,darkThemesetter,notification}=useUserAuth();
    const [isNotificationPageVisible, setNotificationPageVisible] =
    useState(false);

  const toggleNotificationPage = () => {
    setNotificationPageVisible(!isNotificationPageVisible);
  };
    const darkClassName=darkThemesetter ? "nav-dark" :"nav-light"
    const logoImg = darkThemesetter ?  WhiteLogo : LogoNormalMode
return(
    <nav className={`navbar-dashboard ${darkClassName}`}>
        {/* <h1 className="navbar-dashboard-head">Smarttuter</h1> */}
        <div className="logo">
          <img src={logoImg} alt="logo" className="smart-logo-img"/>
        
        </div>
        <div className="right-side-bar">
            <div className="navbar-noti-icons">
                {darkThemesetter? <LuSunMoon className="moon-dark-theme" size="30" onClick={darkTheme}/>:<IoMoonOutline className="moon-dark-theme" size="30" onClick={darkTheme}/>}
                {/* <IoIosNotificationsOutline className="notification-bell" size="30"/> */}
                {/* <div className="navbar_notification">
            <IoIosNotificationsOutline
              className={
                isNotificationPageVisible
                  ? `notification-bell${"-active"}`
                  : "notification-bell"
              }
              size="35"
              onClick={toggleNotificationPage}
            /> 

            <p>{notification && notification.length}</p>
          </div> */}
          {isNotificationPageVisible && (
            <Notification isNotificationPageVisible />
          )}
            </div>
            <div>
                <RightSide/>
            </div>   
        </div>   
    </nav>
)
}
export default NavbarDashboard