import { Link } from "react-router-dom"
import { useUserAuth } from "../../Context/UseAuthContext"
import "./cardItems.css"
import { Button } from "@mui/material"

const CardItem=props=>{
    const {data,handleDeletepost,showPostForm} = props 
    const {MaterialName,MaterialImage,approve,_id}=data
    // const isApproved = approve ? "Approved" : "unApproved"
    const {darkThemesetter}=useUserAuth();
    const backgroundColor =  darkThemesetter ? "darktheme-view":"lighttheme-view"

    
const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "...";
  }
  return text;
};
   
    return(
        <div className={`card-item-css ${backgroundColor}`}>
                <img src={MaterialImage} alt={MaterialName} className="card-item-css-image"/>
            <div className="card-item-css-sub" >
                <Link to={`/studymaterial/${_id}`} className="card-item-css-Name" >
                      <p>{truncateText(MaterialName, 15)}</p>
                </Link>
                <div className="edit-delete-button-component">
                    <Button
                        variant="outlined"
                        onClick={() => showPostForm(data)}
                        disabled={approve}
                      >
                         Edit
                      </Button>
                    <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeletepost(data)}
                          disabled={approve}
                        >
                          Delete
                    </Button>
                 </div>
                </div>
        </div>

    )
}
export default CardItem