import React from 'react';
import { Button} from '@mui/material';
import BankDetailsDialog from './BankDetailsDialog';
import './BankDetailsDisplay.css'

const BankDetailsDisplay = ({handleOpenDialog,open,onClose,onSave,bankDetails}) => {


  return (
    <div className="bank-details-container">
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Enter Bank Details
      </Button>
      <BankDetailsDialog open={open} onClose={onClose} onSave={onSave} />
      <div className="singleenduser-bankdetails-main">
        {bankDetails.map((e, i) => (
          <div
            className="singleenduser-bankdetails"
            key={i}
            style={{
              padding: "16px",
              marginTop: "16px",
              border: "1px solid #ccc",
            }}
          >
            <h3>Bank Details</h3>
            <lebel>Account No</lebel>
            <p style={{ overflowX: "scroll" }}>{e.accountNumber}</p>
            <lebel>Bank Name</lebel>
            <p style={{ overflowX: "scroll" }}>{e.bankName}</p>
            <lebel>IFSC Code</lebel>
            <p style={{ overflowX: "scroll" }}> {e.ifscCode}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BankDetailsDisplay;
