import React from "react";
import { Route, Routes } from "react-router-dom";

import { Payment } from "../page/Payment/Payment";
// import { Login } from "../page/Authentication/Login";
import { CreatePost } from "../page/Post/CreatePost";
import Login from "../components/login/Login";
// import Registration from "../components/registration/Registration"
import Passwordreset from "../page/Authentication/Passwordreset";
import PrivateRotes from "./PrivateRotes";
import ForgotPassword from "../page/Authentication/ForgotPassword";
import SinglePost from "./Table/SinglePost/SinglePost";
import CreateProject from "../page/Project/CreateProject";
import SingleProject from "./ProjectTable/SingleProject/SingleProject";
import StudyMaterial from "../page/StudyMaterial/StudyMaterial";
import SingleStudyMaterial from "./StudyMaterial/SingleStudyMaterial/SingleStudyMaterial";
import Analytics from "./analytics/analytics";
import PageNotFound from "./PageNotFound/PageNotFound";
import ProfileForm from "../page/Profile/Profile";
import { Register } from "../page/Authentication/Register";


export const AllRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRotes>
            {/* <Home /> */}
            <Analytics />
          </PrivateRotes>
        }
      />
      <Route
        path="/createpost"
        element={
          <PrivateRotes>
            <CreatePost />
          </PrivateRotes>
        }
      />
      <Route
        path="/post/:postID"
        element={
          <PrivateRotes>
            <SinglePost />
          </PrivateRotes>
        }
      />

      <Route
        path="/createproject"
        element={
          <PrivateRotes>
            <CreateProject />
          </PrivateRotes>
        }
      />
      <Route
        path="/project/:projectID"
        element={
          <PrivateRotes>
            <SingleProject />
          </PrivateRotes>
        }
      />

      <Route
        path="/createstudymaterial"
        element={
          <PrivateRotes>
            <StudyMaterial />
          </PrivateRotes>
        }
      />
      <Route
        path="/studymaterial/:studymaterialID"
        element={
          <PrivateRotes>
            <SingleStudyMaterial />
          </PrivateRotes>
        }
      />

      <Route
        path="/payment"
        element={
          <PrivateRotes>
            <Payment />
          </PrivateRotes>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRotes>
            <ProfileForm />
          </PrivateRotes>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/passwordreset" element={<Passwordreset />} />
      <Route path="/forgotpassword/:id/:token" element={<ForgotPassword />} />

      <Route
        path="*"
        element={
          <PrivateRotes>
            <PageNotFound />
          </PrivateRotes>
        }
      />
    </Routes>
  );
};
