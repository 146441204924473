import React from "react";
import BasicTable from "../../components/Table/Table";
import { useUserAuth } from "../../Context/UseAuthContext";
import "./CreatePost.css";

export const CreatePost = () => {
  const { darkThemesetter } = useUserAuth();
  const courseBackground = darkThemesetter
    ? "course-darktheme-color"
    : "course-lighttheme-color";
  return (
    <div className={courseBackground}>
      {/* <h1 className={`create-course-heading ${color}`}>Create Course</h1> */}
      <div className="SectionDashBoardVendorAnalyticsHeader">
        <h1>Create Course</h1>
        <p>
          Course Development: Creating Comprehensive Course for Smartuter
        </p>
        <hr />
      </div>
      <BasicTable />
    </div>
  );
};
