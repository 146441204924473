import * as React from "react";
import { UilFilePlus } from "@iconscout/react-unicons";
import ProjectCardItems from "../ProjectsCardItems/ProjectCardItems";
import "./Project.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useUserAuth } from "../../Context/UseAuthContext";
import { ColorRing } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
export default function Projecttable() {
  const {
    fetchProjectData,
    projects,
    getCourseCategory,
    getMainCategory,
    getSubCategory,
    courseCategory,
    subCategory,
    mainCategory,
    darkThemesetter,
  } = useUserAuth();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [abstractword, setAbstractword] = useState("");

  const [imageURL, setImage] = useState(null);
  const [pdfURL, setPDFUrl] = useState(null);
  const [zipURL, setZipUrl] = useState(null);
  const [price, setPrice] = useState(null);
  const [singlepost, setsingelPost] = useState("");
  const [tags, setTags] = useState([]);

  const [editName, setEditName] = useState(""); // State to store edited name
  const [editMessage, setEditMessage] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editabstractword, setEditAbstractWord] = useState("");
  const [editTags, setEditTags] = useState([]);
  const [editMainCategory, setEditMainCategory] = useState(null);
  const [editSubCategory, setEditSubCategory] = useState(null);
  const [editCourseCategory, setEditCourseCategory] = useState(null);

  const [editPdfUrl, setEditPdfUrl] = useState(null);
  const [editZipUrl, setEditZipUrl] = useState(null);
  const [editImageUrl, setEditImgUrl] = useState(null);
  const [showform, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [mainCategoryselectedOption, setMainCategoryesSelectedOption] =
    useState(null);

  const [subCategoryselectedOption, setSubCategoryesSelectedOption] =
    useState(null);
  const [courseCategoryselectedOption, setCourseCategoryesSelectedOption] =
    useState(null);
  // ProgressBar
  const [imageUploadPogress, setImageUploadPogress] = useState(null);
  const [zipUploadPogress, setZipUploadPogress] = useState(null);
  const [pdfUploadPogress, setPdfPogress] = useState(null);
  const [uploadPogress, setUploadPogress] = useState(0);
  const [zipxhr, setzipxhr] = useState(null);
  const [imagexhr, setimagexhr] = useState(null);
  const [pdfxhr, setpdfxhr] = useState(null);

  // const [smallDevice, setIsSmallDevice] = useState(window.innerWidth <= 768);
  const [approveCourseFilter, setApproveCourseFilter] = useState("All");

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const postsText = darkThemesetter ? "dark-text" : "light-text";

  const inputColor = darkThemesetter ? "#f7f8fb" : "black";

  const handleOptionChangeMainCategory = (e) => {
    setMainCategoryesSelectedOption(e.target.value);
  };

  const handleOptionChangeSubCategory = (e) => {
    setSubCategoryesSelectedOption(e.target.value);
  };

  const handleOptionChangeCourseCategory = (e) => {
    setCourseCategoryesSelectedOption(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];

    try {
      if (selectedFile.type.startsWith("image")) {
        // if (selectedFile.size < 1 * 1024 * 1024) {
        await handleUpload(selectedFile, "image");
        // } else {
        //   alert("Upload File Less Than 1 MB", {
        //     duration: 6000,
        //   });
        // setLoading(false);
        // }
      } else if (
        selectedFile.type.startsWith("application/pdf") ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        selectedFile.type === "application/vnd.ms-powerpoint" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        // if (selectedFile.size < 3 * 1024 * 1024) {
        await handleUpload(selectedFile, "pdf");
        // } else {
        //   alert("Upload File Less Than 3 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else if (
        selectedFile.type === "application/zip" ||
        selectedFile.type === "application/x-zip-compressed"
      ) {
        // if (selectedFile.size < 5 *1024 * 1024) {
        await handleUpload(selectedFile, "zip");
        // } else {
        //   alert("Upload File Less Than 5 MB", {
        //     duration: 6000,
        //   });
        //   setLoading(false);
        // }
      } else {
        // console.error("Unsupported file type");
        setLoading(false);

        toast.error("Unsupported file type");
      }
    } catch (error) {
      setLoading(false);

      console.error("Error uploading file:", error.message);
    }
  };

  const handleUpload = async (file, fileType) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const xhr = new XMLHttpRequest();

      xhr.open("POST", "https://smartuter.com/api/project/uploadProjectfile");

      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          // console.log(`Upload Progress: ${percentComplete.toFixed(2)}%`);
          setUploadPogress(percentComplete.toFixed(2));
          if (fileType === "image") {
            setImageUploadPogress(`${percentComplete.toFixed(2)}`);
            setimagexhr(xhr);
            return;
          }
          if (fileType === "zip") {
            setZipUploadPogress(`${percentComplete.toFixed(2)}`);
            setzipxhr(xhr);
            return;
          }

          if (
            fileType === "pdf" ||
            fileType === "ppt" ||
            fileType === "pptx" ||
            fileType === "doc" ||
            fileType === "docx"
          ) {
            setPdfPogress(`${percentComplete.toFixed(2)}`);
            setpdfxhr(xhr);
            return;
          }
        }
      });

      xhr.onload = function () {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          handleUploadSuccess(data.fileUrl, fileType);
        } else {
          console.error("Error uploading file:", xhr.statusText);
          toast.error("Upload failed");
        }
      };

      xhr.onerror = function () {
        console.error("Network error during upload");
        toast.error("Upload failed");
      };

      xhr.send(formData);
    } catch (error) {
      toast.error(error.message);
    }

    // const response = await fetch(
    //   "https://smartuter.com/api/project/uploadProjectfile",
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       // Add other necessary headers
    //     },
    //     body: formData,
    //   }
    // );

    // if (response.ok) {
    //   const data = await response.json();
    //   handleUploadSuccess(data.fileUrl, fileType);
    // } else {
    //   console.error("Error uploading file:", response.statusText);
    //   throw new Error("Upload failed");
    // }
  };

  const handleUploadSuccess = (fileUrl, fileType) => {
    if (fileType === "image") {
      setImage(fileUrl);
      setLoading(false);
    } else if (fileType === "pdf") {
      setPDFUrl(fileUrl);
      setLoading(false);
    } else if (fileType === "zip") {
      setZipUrl(fileUrl);
      setLoading(false);
    } else {
      console.error("Unsupported file type");
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const payload = {
      ProjectTitle: name,
      ProjectDescription: message,
      Abstract200Words: abstractword,
      TechnologiesUsed: tags,
      OutputScreens: imageURL,
      UploadDocumentFile: pdfURL,
      UploadCodeFile: zipURL,
      mainCategory: mainCategoryselectedOption,
      subCategory: subCategoryselectedOption,
      // courseCategory: courseCategoryselectedOption,
      Price: price,
      approve: false,
    };
    // Function to validate the payload
    const validatePayload = (payload) => {
      for (const key in payload) {
        if (payload[key] === null || payload[key] === "") {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
      }
      return true;
    };

    // Validate the payload before making the API call
    if (!validatePayload(payload)) {
      return; // Stop execution if validation fails
    }
    fetch("https://smartuter.com/api/project/create", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchProjectData();
        setShowForm(false);
      })
      .catch((err) => console.log(err));

    handleClose();
  };

  const showPostForm = (e) => {
    setsingelPost(e);
    setEditName(e.ProjectTitle); // Populate the name field with the selected e's name
    setEditMessage(e.ProjectDescription);
    setEditAbstractWord(e.Abstract200Words);
    setEditPrice(e.Price);
    setEditTags(e.TechnologiesUsed);
    setEditCourseCategory(e.courseCategory);
    setEditSubCategory(e.subCategory);
    setEditMainCategory(e.mainCategory);
    setEditImgUrl(e.OutputScreens);
    setEditPdfUrl(e.UploadDocumentFile);
    setEditZipUrl(e.UploadCodeFile);
    setShowForm(true);
  };

  const handleDeletepost = (e) => {
    const projectID = e._id;
    setPostIdToDelete(projectID);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (postIdToDelete) {
      fetch(`https://smartuter.com/api/project/delete/${postIdToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          fetchProjectData();
        })
        .catch((err) => console.log(err));
    }
    // Close the confirmation popup
    setShowConfirmation(false);
  };

  const handleEditformSubmit = () => {
    const payload = {
      ProjectTitle: editName,
      ProjectDescription: editMessage,
      Abstract200Words: editabstractword,
      TechnologiesUsed: editTags,
      OutputScreens: imageURL ? imageURL : editImageUrl,
      UploadDocumentFile: pdfURL ? pdfURL : editPdfUrl,
      UploadCodeFile: zipURL ? zipURL : editZipUrl,
      mainCategory: editMainCategory,
      subCategory: editSubCategory,
      // courseCategory: editCourseCategory,
      Price: editPrice,
    };

    // Function to validate the payload
    const validatePayload = (payload) => {
      for (const key in payload) {
        if (payload[key] === null || payload[key] === "") {
          alert(`Please provide a valid value for ${key}`);
          return false;
        }
      }
      return true;
    };

    // Validate the payload before making the API call
    if (!validatePayload(payload)) {
      return; // Stop execution if validation fails
    }

    const projectID = singlepost._id;
    fetch(`https://smartuter.com/api/project/update/${projectID}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,

        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchProjectData();
        setShowForm(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchProjectData();
    getMainCategory();
    getSubCategory();
    getCourseCategory();
  }, []);

  const handletagDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handletagAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handletagDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  // const handleTagClick = (index) => {
  //   console.log("The tag at index " + index + " was clicked");
  // };
  const handleEdittagDelete = (i) => {
    setEditTags(editTags.filter((tag, index) => index !== i));
  };

  const handleEdittagAddition = (tag) => {
    setEditTags([...editTags, tag]);
  };

  const handleEdittagDrag = (tag, currPos, newPos) => {
    const newTags = editTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const onChangeApproveFilter = (event) => {
    setApproveCourseFilter(event.target.value);
  };

  const isProjectsData = projects !== null && projects.length > 0;
  let approvedProjects;
  let unApprovedProjects;

  if (isProjectsData) {
    approvedProjects = projects.filter((item) => item.approve === true);
    unApprovedProjects = projects.filter((item) => item.approve === false);
  }

  return (
    <div className="Table">
      <div className="recent-history">
        <div className="recent-history-heading-items-container">
          <h3 className="recent-history-heading">Recent Project</h3>
          <div className="recent-history-select-items">
            <select
              value={approveCourseFilter}
              className="select-item-element"
              onChange={onChangeApproveFilter}
            >
              <option value="All">All</option>
              <option value="Approved">Approved</option>
              <option value="UnApproved">UnApproved</option>
            </select>

            <div>
              <Button variant="outlined" onClick={handleClickOpen}>
                <UilFilePlus /> Add
              </Button>
              {/* {loading && (
                <div className="loading-overlay">
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              )} */}
              <Dialog open={open} onClose={handleClose}>
                <Toaster />
                <div className={postsTheme}>
                  <DialogTitle>Submit Your Information</DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText className={postsText}>
                      Please fill out the form below:
                    </DialogContentText> */}
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Title"
                      type="text"
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                      margin="dense"
                      id="message"
                      label="Project area"
                      type="text"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      multiline
                      projects={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <TextField
                      margin="dense"
                      id="message"
                      label="Abstract"
                      type="text"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      multiline
                      projects={4}
                      value={abstractword}
                      onChange={(e) => setAbstractword(e.target.value)}
                    />
                    TechnologiesUsed/Keywords/tags
                    <ReactTags
                      tags={tags}
                      style={{
                        marginBottom: "20px",
                      }}
                      // suggestions={suggestions}
                      delimiters={delimiters}
                      handleDelete={handletagDelete}
                      handleAddition={handletagAddition}
                      handleDrag={handletagDrag}
                      // handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                      classNames={{
                        tagInputField: "ReactTags__tagInput",
                        tags: "ReactTags__tags",
                        tag: "ReactTags__tag",
                        remove: "ReactTags__remove",
                        marginBottom: "20px",
                      }}
                    />
                    Project output image{" "}
                    {imageUploadPogress && (
                      <>
                        <progress value={imageUploadPogress} max="100" />{" "}
                        <button
                          onClick={() => (
                            imagexhr.abort(),
                            setImageUploadPogress(null),
                            setLoading(false),
                            setImage(null)
                          )}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <TextField
                      margin="dense"
                      id="image"
                      // label="Upload Image..."
                      type="file"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      inputProps={{
                        accept: "image/*",
                      }}
                      onChange={handleFileChange}
                      disabled={loading}
                    />
                    Upload project code zip{" "}
                    {zipUploadPogress && (
                      <>
                        <progress value={zipUploadPogress} max="100" />

                        <button
                          onClick={() => (
                            zipxhr.abort(),
                            setZipUploadPogress(null),
                            setLoading(false),
                            setZipUrl(null)
                          )}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <TextField
                      margin="dense"
                      id="file"
                      // label="Upload Zip..."
                      type="file"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      inputProps={{
                        accept: ".zip",
                      }}
                      disabled={loading}
                      onChange={handleFileChange}
                    />
                    Upload project .pdf/ppt/doc{" "}
                    {pdfUploadPogress && (
                      <>
                        <progress value={pdfUploadPogress} max="100" />

                        <button
                          onClick={() => (
                            pdfxhr.abort(),
                            setPdfPogress(null),
                            setLoading(false),
                            setPDFUrl(null)
                          )}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <TextField
                      margin="dense"
                      id="file"
                      // label="Upload Pdf..."
                      type="file"
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      fullWidth
                      inputProps={{
                        accept: ".pdf, .ppt, .pptx, .doc, .docx",
                      }}
                      onChange={handleFileChange}
                      disabled={loading}
                    />
                    {/* MainCategory */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Main Category
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mainCategoryselectedOption || ""}
                        label="Main Category"
                        sx={{
                          color: inputColor,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: inputColor,
                            },
                            "&:hover fieldset": {
                              borderColor: inputColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: inputColor,
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: inputColor,
                          },
                        }}
                        style={{ width: "100%", marginBottom: "20px" }}
                        onChange={handleOptionChangeMainCategory}
                      >
                        {mainCategory &&
                          mainCategory.map((el, i) => (
                            <MenuItem
                              key={el._id}
                              value={el.category}
                              sx={{
                                color: darkThemesetter ? "black" : "black",
                                background: darkThemesetter
                                  ? "rgb(88 85 85 / 87%)"
                                  : "#f7f8fb",
                              }}
                            >
                              {el.category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {/* SubCategory */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Sub Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subCategoryselectedOption || ""}
                        label="Sub Category"
                        sx={{
                          color: inputColor,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: inputColor,
                            },
                            "&:hover fieldset": {
                              borderColor: inputColor,
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: inputColor,
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: inputColor,
                          },
                        }}
                        style={{ width: "100%", marginBottom: "20px" }}
                        onChange={handleOptionChangeSubCategory}
                      >
                        {subCategory &&
                          subCategory.map((el, i) => (
                            <MenuItem
                              key={el._id}
                              value={el.category}
                              sx={{
                                color: darkThemesetter ? "black" : "black",
                                background: darkThemesetter
                                  ? "rgb(88 85 85 / 87%)"
                                  : "#f7f8fb",
                              }}
                            >
                              {el.category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {/* ChildCategory
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={courseCategoryselectedOption || ""}
                      label="Course Category"
                      sx={{
                        color: inputColor,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                          "&:hover fieldset": {
                            borderColor: inputColor,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: inputColor,
                        },
                      }}
                      style={{ width: "100%" }}
                      onChange={handleOptionChangeCourseCategory}
                    >
                      {courseCategory &&
                        courseCategory.map((el, i) => (
                          <MenuItem
                            key={el._id}
                            value={el.category}
                            sx={{
                              color: darkThemesetter ? "black" : "black",
                              background: darkThemesetter
                                ? "rgb(88 85 85 / 87%)"
                                : "#f7f8fb",
                            }}
                          >
                            {el.category}
                          </MenuItem>
                        ))}
                    </Select> */}
                    <TextField
                      margin="dense"
                      id="message"
                      label="Additional information if any
"
                      type="text"
                      fullWidth
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                        marginBottom: "20px",
                      }}
                      multiline
                      projects={4}
                      // value={message}
                      // onChange={(e) => setMessage(e.target.value)}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Cost"
                      type="number"
                      InputProps={{
                        style: { color: inputColor },
                      }}
                      InputLabelProps={{
                        style: { color: inputColor },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: inputColor,
                          },
                        },
                      }}
                      fullWidth
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {loading ? (
                      <Button>in Progress {uploadPogress}%</Button>
                    ) : (
                      // <ColorRing
                      //   visible={true}
                      //   height="80"
                      //   width="80"
                      //   ariaLabel="color-ring-loading"
                      //   wrapperStyle={{}}
                      //   wrapperClass="color-ring-wrapper"
                      //   colors={[
                      //     "#e15b64",
                      //     "#f47e60",
                      //     "#f8b26a",
                      //     "#abbd81",
                      //     "#849b87",
                      //   ]}
                      // />
                      <Button onClick={handleSubmit}>Submit</Button>
                    )}
                  </DialogActions>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

      <ul className="list-of-card-items">
        {isProjectsData ? (
          <>
            {approveCourseFilter === "All" ? (
              projects.length > 0 ? (
                projects.map((item, index) => (
                  <ProjectCardItems
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="not-found"
                  />
                  <p className="no-course-found-text">No courses found.</p>
                </div>
              )
            ) : null}

            {approveCourseFilter === "Approved" ? (
              approvedProjects.length > 0 ? (
                approvedProjects.map((item, index) => (
                  <ProjectCardItems
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="not-found"
                  />
                  <p className="no-course-found-text">
                    No approved courses found.
                  </p>
                </div>
              )
            ) : null}

            {approveCourseFilter === "UnApproved" ? (
              unApprovedProjects.length > 0 ? (
                unApprovedProjects.map((item, index) => (
                  <ProjectCardItems
                    data={item}
                    key={index}
                    showPostForm={showPostForm}
                    handleDeletepost={handleDeletepost}
                  />
                ))
              ) : (
                <div className="no-course-found-container">
                  <img
                    src="https://res.cloudinary.com/dyqpzx80s/image/upload/v1713333642/no-course-found_ifervi.jpg"
                    className="no-course-found-img"
                    alt="not-found"
                  />
                  <p className="no-course-found-text">
                    No unapproved courses found.
                  </p>
                </div>
              )
            ) : null}
          </>
        ) : null}
      </ul>
      <Dialog open={showform} onClose={() => setShowForm(false)}>
        <Toaster />
        <div className={postsTheme}>
          <DialogTitle>Edit Project</DialogTitle>
          <DialogContent>
            <DialogContentText>Edit the selected Project:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Title"
              type="text"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              value={editName}
              onChange={(e) => setEditName(e.target.value)} // Update editName state
            />
            <TextField
              margin="dense"
              id="editMessage"
              label="Project area"
              type="text"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              multiline
              projects={4}
              value={editMessage}
              onChange={(e) => setEditMessage(e.target.value)} // Update editMessage state
            />
            <TextField
              margin="dense"
              id="editAbstract200Words"
              label="Abstract"
              type="text"
              fullWidth
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              multiline
              projects={4}
              value={editabstractword}
              onChange={(e) => setEditAbstractWord(e.target.value)} // Update editMessage state
            />
            TechnologiesUsed/Keywords/tags
            <ReactTags
              tags={editTags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleEdittagDelete}
              handleAddition={handleEdittagAddition}
              handleDrag={handleEdittagDrag}
              // handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
              classNames={{
                tagInputField: "ReactTags__tagInput",
                tags: "ReactTags__tags",
                tag: "ReactTags__tag",
                remove: "ReactTags__remove",
              }}
            />
            Project output image{" "}
            {imageUploadPogress && loading && (
              <>
                <progress value={imageUploadPogress} max="100" />{" "}
                <button
                  onClick={() => (
                    imagexhr.abort(),
                    setImageUploadPogress(null),
                    setLoading(false),
                    setEditImgUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="image"
              // label="Upload Image..."
              type="file"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              inputProps={{
                accept: "image/*",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            Upload project code zip{" "}
            {zipUploadPogress && loading && (
              <>
                <progress value={zipUploadPogress} max="100" />

                <button
                  onClick={() => (
                    zipxhr.abort(),
                    setZipUploadPogress(null),
                    setLoading(false),
                    setEditZipUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="file"
              // label="Upload Zip..."
              type="file"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              inputProps={{
                accept: ".zip",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            Upload project .pdf/ppt/doc{" "}
            {pdfUploadPogress && loading && (
              <>
                <progress value={pdfUploadPogress} max="100" />

                <button
                  onClick={() => (
                    pdfxhr.abort(),
                    setPdfPogress(null),
                    setLoading(false),
                    setEditPdfUrl(null)
                  )}
                >
                  Cancel
                </button>
              </>
            )}
            <TextField
              margin="dense"
              id="file"
              // label="Upload Pdf..."
              type="file"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              fullWidth
              inputProps={{
                accept: ".pdf, .ppt, .pptx, .doc, .docx",
              }}
              onChange={handleFileChange}
              disabled={loading}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editMainCategory}
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                  "&:hover fieldset": {
                    borderColor: inputColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              label="Main Category"
              style={{ width: "100%" }}
              onChange={(e) => setEditMainCategory(e.target.value)}
            >
              {mainCategory &&
                mainCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editSubCategory}
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                  "&:hover fieldset": {
                    borderColor: inputColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
                marginBottom: "20px",
              }}
              label="Sub Category"
              style={{ width: "100%" }}
              onChange={(e) => setEditSubCategory(e.target.value)}
            >
              {subCategory &&
                subCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select>
            {/* ChildCategory
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editCourseCategory}
              label="Course Category"
              sx={{
                color: inputColor,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                  "&:hover fieldset": {
                    borderColor: inputColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: inputColor,
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: inputColor,
                },
              }}
              style={{ width: "100%" }}
              onChange={(e) => setEditCourseCategory(e.target.value)}
            >
              {courseCategory &&
                courseCategory.map((el, i) => (
                  <MenuItem
                    key={el._id}
                    value={el.category}
                    sx={{
                      color: darkThemesetter ? "black" : "black",
                      background: darkThemesetter
                        ? "rgb(88 85 85 / 87%)"
                        : "#f7f8fb",
                    }}
                  >
                    {el.category}
                  </MenuItem>
                ))}
            </Select> */}
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Price"
              InputProps={{
                style: { color: inputColor },
              }}
              InputLabelProps={{
                style: { color: inputColor },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: inputColor,
                  },
                },
                marginBottom: "20px",
              }}
              type="number"
              fullWidth
              value={editPrice}
              onChange={(e) => setEditPrice(e.target.value)} // Update editName state
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowForm(false)}>Cancel</Button>
            {loading ? (
              <Button>in Progress {uploadPogress}%</Button>
            ) : (
              // <ColorRing
              //   visible={true}
              //   height="80"
              //   width="80"
              //   ariaLabel="color-ring-loading"
              //   wrapperStyle={{}}
              //   wrapperClass="color-ring-wrapper"
              //   colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              // />
              <Button onClick={handleEditformSubmit}>Submit</Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div className={postsTheme}>
          <DialogTitle>Are you sure you want to delete this post ?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
