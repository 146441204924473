import React, { useState } from "react";
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import { useUserAuth } from "../../Context/UseAuthContext";
import toast from "react-hot-toast";
import "./login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const { setUser } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email,
      pass,
    };
    fetch("https://smartuter.com/api/subadmin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          res.msg === "Login successful" &&
          res.token &&
          res.user.isVerified
        ) {
          toast.success(`${res.msg}`);
          localStorage.setItem("token", res.token);
          setUser(res.user);
          navigate("/");
        } else if (res.token && res.user.isVerified) {
          toast.error(
            "You're not verified by Admin, please wait for a while until approved"
          );
        } else {
          toast.error(`${res.msg}`);
        }

        setEmail("");
        setPass("");
        // if (res.token && res.user.isVerified&&res.msg === "Login successful") {

        //   localStorage.setItem("token", res.token);
        //   setUser(res.user);
        //   navigate("/");
        // } else {
        //   toast.error(
        //     "You're not verified by Admin, please wait for a while until approved"
        //   );
        // }
      })
      .catch((err) => toast.error(`${err.message} Server Error`));
  };

  if (localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }
  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit} className="login-page-card" action="">
        <h1 style={{ textAlign: "center" }}>Login</h1>
        <div className="input-group">
          <label>Email Address</label>
          <input
            type="email"
            placeholder=" Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type={showPass ? "text" : "password"}
            placeholder="Enter Your password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            required
          />
          <span
            className="register-eyebtn"
            onClick={() => setShowPass(!showPass)}
          >
            {!showPass ? (
              <FaEyeSlash color="#A7A9AA" />
            ) : (
              <FaEye color="#A7A9AA" />
            )}
          </span>
        </div>
        <div className="remember-forget">
          <NavLink to={"/passwordreset"} className={"navlink"}>
            Forgot password?
          </NavLink>
        </div>
        <button style={{ width: "94%" }} type="submit" onClick={handleSubmit}>
          Login
        </button>
        <div className="register-link">
          <p>
            Don't have an account?{" "}
            <NavLink className={"navlink"} to={"/register"}>
              <span className="rigistration-login-p">SignUp</span>
            </NavLink>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
