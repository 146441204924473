import React, { useState } from "react";
import "./notification.css";
import { useUserAuth } from "../../Context/UseAuthContext";
import { Link } from "react-router-dom";
import { Dialog, DialogContent, Typography } from "@mui/material";
import axios from "axios";
const Notification = ({ isNotificationPageVisible }) => {
  const { notification, getNotification } = useUserAuth();
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [singleDialog, setSingleDialog] = useState(null);
  const updateValue = async (id) => {
    try {
      await axios.patch(
        "https://smartuter.com/api/notification/updateadminnotification",
        { id: id }
      );
    } catch (error) {
      console.log(error.message);
    }
  };
  const setDialog = (e) => {
    setSingleDialog(e.data);
    setShowNotificationDialog(true);
    updateValue(e._id);
    getNotification();
  };

  return (
    <div className={`notification-page`}>
      <div>
        {notification &&
          notification.map((e, i) => (
            <Link key={i}>
              <div onClick={() => setDialog(e)}>
                <img
                  src={e.data.imageUrl}
                  alt={e.data.postName}
                  onError={(e) => {
                    e.target.src =
                      "https://www.timeoutdubai.com/cloud/timeoutdubai/2021/09/11/hfpqyV7B-IMG-Dubai-UAE-1200x800.jpg";
                  }}
                />
                <h6>{e.data.postName} (Purchased)</h6>
              </div>
            </Link>
          ))}
      </div>

      {singleDialog && (
        <Dialog
          open={showNotificationDialog}
          onClose={() => setShowNotificationDialog(false)}
        >
          <DialogContent>
            {/* {singleDialog.postName} */}
            <Typography variant="h5" component="div">
              {singleDialog.postName}
            </Typography>
            <Typography variant="h6" component="h6">
              Price : {singleDialog.Price}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Notification;
