import { useUserAuth } from "../../Context/UseAuthContext"
import { Link } from "react-router-dom"
import { Button } from "@mui/material"
import "./CourseCardItems.css"
const CourseCardItems = props=>{
    const {data,showPostForm, handleDeletepost } = props 
    const {postName,imageUrl,_id,approve} = data 
    const {darkThemesetter}=useUserAuth();
    const backgroundColor =  darkThemesetter ? "darktheme-view":"lighttheme-view"

     const truncateText = (text, limit) => {
       if (text.length > limit) {
         return text.substring(0, limit) + "...";
       }
       return text;
     };

    return(
    
       
        <div className={`card-item-css ${backgroundColor}`}>
            <img src={imageUrl} alt={postName} className="card-item-css-image"/>
            <div className="card-item-css-sub" >
                <Link to={`/post/${_id}`} className="card-item-css-Name" >
                    <p>{truncateText(postName, 15)}</p>
                </Link>
                <div className="edit-delete-button-component">
                    <Button
                        variant="outlined"
                        onClick={() => showPostForm(data)}
                        disabled={approve}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeletepost(data)}
                        disabled={approve}
                        >
                        Delete
                    </Button>
                </div>
        </div>
</div>
    )
}
export default CourseCardItems