// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-project-technologies {
    background-color: aliceblue;
    /* Alice Blue background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Drop shadow */
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
    /* Adds space between each tag */
    display: inline-block;
    /* Adjusts the size to the content */
}

.sub-admin-name {
    font-weight: bold;
    /* Makes the text bold */
    font-size: 1.2rem;
    /* Increases the font size */
    color: #2c3e50;
    /* Darker color for better readability */
    margin-bottom: 10px;
    /* Adds space below the element */
}

.sub-admin-number {
    font-size: 1rem;
    /* Standard font size */
    color: #16a085;
    /* Teal color for distinction */
    margin-bottom: 10px;
    /* Adds space below the element */
}`, "",{"version":3,"sources":["webpack://./src/components/Table/SinglePost/SinglePost.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,gCAAgC;IAChC,wCAAwC;IACxC,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,gCAAgC;IAChC,qBAAqB;IACrB,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,iBAAiB;IACjB,4BAA4B;IAC5B,cAAc;IACd,wCAAwC;IACxC,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,cAAc;IACd,+BAA+B;IAC/B,mBAAmB;IACnB,iCAAiC;AACrC","sourcesContent":[".single-project-technologies {\n    background-color: aliceblue;\n    /* Alice Blue background color */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    /* Drop shadow */\n    padding: 10px;\n    border-radius: 4px;\n    margin-right: 10px;\n    /* Adds space between each tag */\n    display: inline-block;\n    /* Adjusts the size to the content */\n}\n\n.sub-admin-name {\n    font-weight: bold;\n    /* Makes the text bold */\n    font-size: 1.2rem;\n    /* Increases the font size */\n    color: #2c3e50;\n    /* Darker color for better readability */\n    margin-bottom: 10px;\n    /* Adds space below the element */\n}\n\n.sub-admin-number {\n    font-size: 1rem;\n    /* Standard font size */\n    color: #16a085;\n    /* Teal color for distinction */\n    margin-bottom: 10px;\n    /* Adds space below the element */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
