import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useUserAuth } from '../../../Context/UseAuthContext';

const BankDetailsDialog = ({ open, onClose, onSave }) => {
  const [formValues, setFormValues] = useState({
    accountNumber: '',
    bankName: '',
    ifscCode: '',
  });
  const {darkThemesetter} = useUserAuth()

  const postsTheme = darkThemesetter ? "dark-theme" : "light-theme";
  const inputColor = darkThemesetter ? '#f7f8fb' : 'black';




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
     const { accountNumber, bankName, ifscCode } = formValues;
     if (!accountNumber || !bankName || !ifscCode) {
       alert("All fields are required.");
       return;
     }
    onSave(formValues);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={postsTheme}>
      <DialogTitle>Enter Bank Details</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="accountNumber"
          label="Account Number"
          type="text"
          fullWidth
          InputProps={{
            style: { color: inputColor },
          }}
          InputLabelProps={{
            style: { color: inputColor },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputColor,
              }
            },
          }}
          value={formValues.accountNumber}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="bankName"
          label="Bank Name"
          type="text"
          fullWidth
          InputProps={{
            style: { color: inputColor },
          }}
          InputLabelProps={{
            style: { color: inputColor },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputColor,
              }
            },
          }}
          value={formValues.bankName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="ifscCode"
          label="IFSC Code"
          type="text"
          fullWidth
          InputProps={{
            style: { color: inputColor },
          }}
          InputLabelProps={{
            style: { color: inputColor },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputColor,
              }
            },
          }}
          value={formValues.ifscCode}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
};

export default BankDetailsDialog;
