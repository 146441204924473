// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Study-darktheme-color{
    /* background-color: #111; */
    background-color: rgb(37, 37, 37);
    border-radius: 10px;
    color: white;
    padding: 20px;
}
.Study-lighttheme-color{
    background-color: white;
    color: rgb(30, 30, 54);
    border-radius: 10px;
    padding: 20px;
}

.create-course-heading {
    font-family: "Roboto", sans-serif; 
    font-size: 32px; 
    font-weight: 700;
    text-transform: uppercase; 
    letter-spacing: 2px;
    margin-bottom: 30px; 
    text-align: center; 
    border-bottom: 3px solid #dddddd; 
    padding-bottom: 10px; 
    padding-top: 10px;
}
.darktheme-color{
   color:#ffffff;
   text-shadow: 1px 1px 3px rgb(240, 235, 235);
}
.lighttheme-color{
    color: rgb(2, 2, 19); 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/page/StudyMaterial/StudyMaterial.css"],"names":[],"mappings":";AACA;IACI,4BAA4B;IAC5B,iCAAiC;IACjC,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;AACA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gCAAgC;IAChC,oBAAoB;IACpB,iBAAiB;AACrB;AACA;GACG,aAAa;GACb,2CAA2C;AAC9C;AACA;IACI,oBAAoB;IACpB,2CAA2C;AAC/C","sourcesContent":["\n.Study-darktheme-color{\n    /* background-color: #111; */\n    background-color: rgb(37, 37, 37);\n    border-radius: 10px;\n    color: white;\n    padding: 20px;\n}\n.Study-lighttheme-color{\n    background-color: white;\n    color: rgb(30, 30, 54);\n    border-radius: 10px;\n    padding: 20px;\n}\n\n.create-course-heading {\n    font-family: \"Roboto\", sans-serif; \n    font-size: 32px; \n    font-weight: 700;\n    text-transform: uppercase; \n    letter-spacing: 2px;\n    margin-bottom: 30px; \n    text-align: center; \n    border-bottom: 3px solid #dddddd; \n    padding-bottom: 10px; \n    padding-top: 10px;\n}\n.darktheme-color{\n   color:#ffffff;\n   text-shadow: 1px 1px 3px rgb(240, 235, 235);\n}\n.lighttheme-color{\n    color: rgb(2, 2, 19); \n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
