// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-button{
    width: 95%!important;
    height: 45px;
    background: #2b1b54 !important;
    color: white;
    border: none;
    outline: none;
    border-radius: 1vh;
    margin-top: 3vh;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    cursor: pointer;
    font-size: 16px;
    columns: #333;
    font-weight: 700;
    position: relative;
}
.register-button:hover{
  width: 100%;
  height: 45px;
  background: transparent !important;
  color: blue;
  border: 2px solid blue;
  outline: none;
  border-radius: 1vh;
  margin-top: 3vh;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  cursor: pointer;
  font-size: 16px;
  columns: #333;
  font-weight: 700;
}

.error-message {
  color: red;
}

.register-eyebtn {
  position: absolute;
  /* border: 1px solid red; */
  right: 0%;
  top: 59%;
  /* left: 0; */
}

.register-eyebtn:hover {
  cursor: pointer;
}

.register-eyebtn1 {
  position: absolute;
  /* border: 1px solid red; */
  right: 0%;
  top: 60%;
  /* left: 0; */
}

.register-eyebtn1:hover {
  cursor: pointer;
}

 


`, "",{"version":3,"sources":["webpack://./src/page/Authentication/Register.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,YAAY;IACZ,8BAA8B;IAC9B,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,mCAAmC;IACnC,eAAe;IACf,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,mCAAmC;EACnC,eAAe;EACf,eAAe;EACf,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,SAAS;EACT,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,SAAS;EACT,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".register-button{\n    width: 95%!important;\n    height: 45px;\n    background: #2b1b54 !important;\n    color: white;\n    border: none;\n    outline: none;\n    border-radius: 1vh;\n    margin-top: 3vh;\n    box-shadow: 0 0 10px rgba(0,0,0,.1);\n    cursor: pointer;\n    font-size: 16px;\n    columns: #333;\n    font-weight: 700;\n    position: relative;\n}\n.register-button:hover{\n  width: 100%;\n  height: 45px;\n  background: transparent !important;\n  color: blue;\n  border: 2px solid blue;\n  outline: none;\n  border-radius: 1vh;\n  margin-top: 3vh;\n  box-shadow: 0 0 10px rgba(0,0,0,.1);\n  cursor: pointer;\n  font-size: 16px;\n  columns: #333;\n  font-weight: 700;\n}\n\n.error-message {\n  color: red;\n}\n\n.register-eyebtn {\n  position: absolute;\n  /* border: 1px solid red; */\n  right: 0%;\n  top: 59%;\n  /* left: 0; */\n}\n\n.register-eyebtn:hover {\n  cursor: pointer;\n}\n\n.register-eyebtn1 {\n  position: absolute;\n  /* border: 1px solid red; */\n  right: 0%;\n  top: 60%;\n  /* left: 0; */\n}\n\n.register-eyebtn1:hover {\n  cursor: pointer;\n}\n\n \n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
