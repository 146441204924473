// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Updates.css */
.Updates {
    /* position: relative; */
    
  }
  
  .update {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .noti {
    margin-left: 10px;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* transform: translate(-50%, -50%); */
    background: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* z-index: 1000; */
    height: 60vh !important;
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
.toggle-button-dark-theme{
  border:1px solid white !important;
  color: white !important;;
  margin:1rem !important;
}
.toggle-button-light-theme{
  margin:1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/page/Profile/Profile.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,wBAAwB;;EAE1B;;EAEA;IACE,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,sCAAsC;IACtC,iBAAiB;IACjB,aAAa;IACb,yCAAyC;IACzC,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;AAEF;EACE,iCAAiC;EACjC,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB","sourcesContent":["/* Updates.css */\n.Updates {\n    /* position: relative; */\n    \n  }\n  \n  .update {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n  }\n  \n  .noti {\n    margin-left: 10px;\n  }\n  \n  .profile-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    /* transform: translate(-50%, -50%); */\n    background: white;\n    padding: 20px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    /* z-index: 1000; */\n    height: 60vh !important;\n  }\n  \n  .loading-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(255, 255, 255, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n.toggle-button-dark-theme{\n  border:1px solid white !important;\n  color: white !important;;\n  margin:1rem !important;\n}\n.toggle-button-light-theme{\n  margin:1rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
